.sd_mainstreet {
  position: relative;
  .sd_mainstreet_header {
    background: url("/village/images/simple_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: relative;
    @include mediaQuery($mobile) {
      margin-top: 7px;
    }
    .sd_mainstreet_container {
      max-width: 1510px;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;
      @include mediaQuery(1600px) {
        width: 100%;
      }
      .sd_mainstreet_inner {
        max-width: 1510px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        @include mediaQuery(1600px) {
          width: 100%;
          padding: 0 20px;
        }
        @include mediaQuery($Largemobile) {
          padding: 0 15px;
        }
        @include mediaQuery($mobileSmall) {
          padding: 0 10px;
        }
        .sd_mainstreet_des {
          h1 {
            width: 1300px;
            @include fontStyle(400, 60, 80, $black, -2.16px);
            padding-top: 85px;  
            @include mediaQuery(1700px) {
              width: 100%;
            }
            @include mediaQuery($extraLarge) {
              font-size: 80px;
              padding-top: 50px;
            }
            @include mediaQuery($Largemobile) {
              font-size: 50px;
              padding-top: 40px;
            }
            @include mediaQuery($mobile) {
              font-size: 40px;
              padding-top: 40px;
            }
            @include mediaQuery($mobileSmall) {
              font-size: 35px;
              line-height: 70px;
            }
            @include mediaQuery($smallMobile) {
              font-size: 32px;
            }
          }
          h3 {
            @include fontStyle(400, 40, 80, $black, -0.96px);
            padding-bottom: 27px;
            @include mediaQuery($extraLarge) {
              padding-bottom: 5px;
            }
            @include mediaQuery($Largemobile) {
              line-height: 4rem;
              padding-bottom: 20px;
              font-size: 30px;
            }
            @include mediaQuery($mobileSmall) {
              line-height: 2rem;
              font-size: 25px;
            }
          }
        }
        .sd_mainstreet_logo {
          width: 185px;
          padding-bottom: 37px;
          @include mediaQuery($extraLarge) {
            padding-bottom: 20px;
          }
          img {
            width: 100%;
          }
        }
        .sd_mainstreet_para {
          width: 714px;
          padding-bottom: 300px;
          @include mediaQuery($Largemobile) {
            width: 100%;
          }
          @include mediaQuery($mobile) {
            padding-bottom: 145px;
          }

          p {
            @include fontStyle(400, 22, 27, $black, 0px);
            font-size: 2.2rem !important;
            @include mediaQuery($Largemobile) {
              font-size: 20px;
            }
            @include mediaQuery($mobile) {
              font-size: 17px;
            }
            @include mediaQuery($mobileSmall) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  .sd_header_hero {
    max-width: 1510px;
    // width: 100%;
    // margin: 0 auto;
    padding: 0 15px;
    margin: -256px auto 0;
    z-index: 5;
    @include mediaQuery(1600px) {
      width: 100%;
      padding: 0 30px;
    }
    @include mediaQuery($mobile) {
      margin: -110px auto 0;
      padding: 0 0px;
    }
   
    img {
      width: 100%;
      min-height: 100px;
      max-height: 809px;
      @include mediaQuery($iPad) {
        max-height: 100%;
        height: auto;
      }
    }
    .sd_header_date_day {
      position: absolute;
      width: 82px;
      height: 82px;
      padding: 16px 0 11px;
      margin: 0 auto;
      background-color: $darkpink;
      text-align: center;
      p {
        @include fontStyle(300, 14, 20, $white, -0.336px);
      }
      h3 {
        @include fontStyle(500, 30, 40, $white, -0.72px);
      }
    }
    .sd_header_hero_des {
      padding-top: 20px;
      width: 955px;
      @include mediaQuery($extraLarge) {
        width: 100%;
      }
      @include mediaQuery($mobile) {
        padding-top: 10px;
        padding: 15px !important;
      }
      h2 {
        padding-bottom: 20px;
        @include fontStyle(400, 40, 40, $black, -0.96px);
        @include mediaQuery($iPad) {
          font-size: 30px;
        }
        @include mediaQuery($Largemobile) {
          font-size: 25px;
          line-height: 30px;
          padding-bottom: 10px;
        }
        @include mediaQuery($mobile) {
          font-size: 20px;
          line-height: 27px;
        }
      }
      span {
        display: flex;
        align-items: center;
        button {
          border: none;
          background: none;
          outline: none;
          cursor: pointer;
        }
        @include fontStyle(700, 22, 23, $black, -0.528px);
        .sd_main_time {
          padding-left: 4px;
        }
        @include mediaQuery($mobile) {
          font-size: 18px;
        }
        img {
          width: 20px;
          height: 20px;
          min-height: 100%;
          max-height: 100%;
          margin-left: 10px;
          cursor: pointer;
        }
      }
      a {
        @include fontStyle(500, 22, 80, $black, -0.528px);
        border: 2px solid;
        min-width: 183px;
        padding: 0 20px;
        display: inline-block;
        text-align: center;
        &:hover {
          background-color: $black;
          color: $white;
        }
        @include mediaQuery($extraLarge) {
          line-height: 70px;
          
        }
        @include mediaQuery($Largemobile) {
          font-size: 22px;
          line-height: 60px;
        }
        @include mediaQuery($mobile) {
          font-size: 18px;
          line-height: 50px;
          min-width: 137px;
        }
        @include mediaQuery($smallMobile) {
          line-height: 50px;
        }
      }
    }
  }
}
// block
.sd_mainstreet_Block {
  max-width: 1510px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 15px 0 15px;
  // padding-top: 70px;
  @include mediaQuery(1600px) {
    width: 100%;
  }
  @include mediaQuery($iPad) {
    padding: 40px 20px 0;
  }
  @include mediaQuery($mobile) {
    padding: 25px 15px 0;
  }
  @include mediaQuery($smallMobile) {
    padding: 25px 10px 0;
  }
  .sd_mainstreet_Block_inner {
    display: flex;
    flex-wrap: wrap;
    .sd_mainstreet_block_Sec {
      flex-basis: 50%;
      padding: 0 15px;
      @include mediaQuery($iPad) {
        flex-basis: 100%;
        padding: 0;
      }
      box-sizing: border-box;
      position: relative;
      &:nth-child(odd)
      {
        padding-left: 0;
      }
      &:nth-child(even)
      {
        padding-right: 0;
      }
      .sd_header_date_day {
        position: absolute;
        width: 82px;
        height: 82px;
        padding: 16px 0 11px;
        margin: 0 auto;
        background-color: $darkpink;
        text-align: center;
        p {
          @include fontStyle(300, 14, 20, $white, -0.336px);
        }
        h3 {
          @include fontStyle(500, 30, 40, $white, -0.72px);
        }
      }
      .sd_mainstreet_block_img {
        padding-bottom: 9px;
        img {
          width: 100%;
          max-height: 403px;
          min-height: 100px;
          @include mediaQuery($desktop) {
            height: 100%;
          }
        }
      }
      .sd_mainstreet_block_des {
        padding-bottom: 60px;
        @include mediaQuery($Largemobile) {
          padding-bottom: 30px;
        }
        @include mediaQuery($mobile) {
          padding-bottom: 25px;
        }
        h2 {
          padding-bottom: 20px;
          @include fontStyle(400, 40, 40, $black, -0.96px);
          @include mediaQuery($iPad) {
            font-size: 30px;
          }
          @include mediaQuery($Largemobile) {
            font-size: 25px;
            line-height: 30px;
            padding-bottom: 10px;
          }
          @include mediaQuery($mobile) {
            font-size: 20px;
            line-height: 27px;
          }
        }
        span {
          display: flex;
          align-items: center;
          button {
            background: none;
            outline: none;
            border: none;
            cursor: pointer;
          }
          img {
            width: 20px;
            height: 20px;
            margin-left: 20px;
            cursor: pointer;
          }
          @include fontStyle(700, 22, 23, $black, -0.528px);
          @include mediaQuery($Largemobile) {
            font-size: 20px;
          }
          @include mediaQuery($mobile) {
            font-size: 18px;
          }
        }

        a {
          @include fontStyle(500, 22, 80, $black, -0.528px);
          border: 2px solid;
          min-width: 183px;
          padding: 0 20px;
          display: inline-block;
          text-align: center;
          &:hover {
            background-color: $black;
            color: $white;
          }
          @include mediaQuery($extraLarge) {
            line-height: 70px;
          }
          @include mediaQuery($Largemobile) {
            font-size: 22px;
            line-height: 60px;
          }
          @include mediaQuery($mobile) {
            font-size: 18px;
            line-height: 50px;
            min-width: 137px;
          }
          @include mediaQuery($smallMobile) {
            line-height: 50px;
          }
        }
      }
    }
  }
}

.sd_main_paragraph {
  padding-bottom: 66px !important;
}

.mainstreet_span {
  padding: 8px 0 22px;
}
.mainstreet_span-para {
  padding-bottom: 10px;
}
.sd_mainstreet_Block_padd {
  width: 1480px;
  margin: 0 auto;
  padding-top: 0;
  @include mediaQuery(1600px) {
    width: 100%;
  }
  @include mediaQuery($iPad) {
    padding: 40px 20px 0;
  }
  @include mediaQuery($mobile) {
    padding: 25px 15px 0;
  }
  @include mediaQuery($smallMobile) {
    padding: 25px 10px 0;
  }
  .sd_mainstreet_Block_inner {
    display: flex;
    flex-wrap: wrap;
    .sd_mainstreet_block_Sec {
      flex-basis: 50%;
      padding: 0 15px;
      @include mediaQuery($iPad) {
        flex-basis: 100%;
        padding: 0;
      }
      box-sizing: border-box;
      position: relative;
      .sd_header_date_day {
        position: absolute;
        width: 82px;
        height: 82px;
        padding: 16px 0 11px;
        margin: 0 auto;
        background-color: $darkpink;
        text-align: center;
        p {
          @include fontStyle(300, 14, 20, $white, -0.336px);
        }
        h3 {
          @include fontStyle(500, 30, 40, $white, -0.72px);
        }
      }
      .sd_mainstreet_block_img {
        padding-bottom: 9px;
        img {
          width: 100%;
          max-height: 403px;
          min-height: 100px;
          @include mediaQuery($desktop) {
            height: 100%;
          }
        }
      }
      .sd_mainstreet_block_des {
        padding-bottom: 60px;
        @include mediaQuery($Largemobile) {
          padding-bottom: 30px;
        }
        @include mediaQuery($mobile) {
          padding-bottom: 25px;
        }
        h2 {
          padding-bottom: 20px;
          @include fontStyle(400, 40, 40, $black, -0.96px);
          @include mediaQuery($iPad) {
            font-size: 30px;
          }
          @include mediaQuery($Largemobile) {
            font-size: 25px;
            line-height: 30px;
            padding-bottom: 10px;
          }
          @include mediaQuery($mobile) {
            font-size: 20px;
            line-height: 27px;
          }
        }
        span {
          @include fontStyle(500, 22, 23, $black, -0.528px);
          @include mediaQuery($Largemobile) {
            font-size: 20px;
          }
          @include mediaQuery($mobile) {
            font-size: 19px;
          }
        }

        a {
          @include fontStyle(500, 22, 80, $black, -0.528px);
          border: 2px solid;
          min-width: 183px;
          display: inline-block;
          text-align: center;
          &:hover {
            background-color: $black;
            color: $white;
          }
          @include mediaQuery($extraLarge) {
            line-height: 70px;
          }
          @include mediaQuery($Largemobile) {
            font-size: 22px;
            line-height: 60px;
          }
          @include mediaQuery($mobile) {
            font-size: 18px;
            line-height: 60px;
          }
          @include mediaQuery($smallMobile) {
            line-height: 50px;
          }
        }
      }
    }
  }
}
