.sd_blackhouse_event {
  background: $yellow;
  .sd_sponsoredby {
    img {
      max-width: 110px;
    }
  }
  .sd_simplelp_section {
    .sd_btn {
      margin-top: 20px;
    }
  }
  .sd_full_image {
    margin: 70px 0;
    @include mediaQuery($extraLarge) {
      margin: 55px 0;
    }
  }
  .sd_event_details {
    padding-bottom: 200px;
    margin: 0;
    @include mediaQuery($extraLarge) {
      padding-bottom: 55px;
    }
  }
}
.sd_blackhouse_section {
  &.sd_fullwidth_slider {
    margin-top: -120px;
    margin-bottom: 120px;
    @include mediaQuery($extraLarge) {
      margin: 55px 0;
    }
    .sd_container.sd_inner_container {
      max-width: 1710px;
    }
    .sd_gallery_slider {
      max-width: 1516px;
      width: 100%;
      margin: 0 auto;
      padding: 0 100px;
      @include mediaQuery($iPad) {
        padding: 0 50px;
      }
      .sd_gallery_image {
        img {
          width: 100%;
        }
      }
    }
    .slick-prev {
      left: -81px;
      @include mediaQuery($iPad) {
        left: -41px;
      }
    }
    .slick-next {
      right: -81px;
      @include mediaQuery($iPad) {
        right: -41px;
      }
    }
  }
  .sd_blackhouse_heading {
    text-align: center;
    .sd_slider_title {
      font-size: 6rem;
      line-height: 6rem;
      letter-spacing: -1.44px;
      @include mediaQuery($iPad) {
        font-size: 4rem;
        line-height: 4rem;
      }
      @include mediaQuery($mobile) {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
    .sd_btn {
      margin-top: 20px;
      min-width: 220px;
    }
  }
}


