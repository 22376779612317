/* for common fonts */
@mixin defaultFontStyle($fontWeight: null,$fontSize: null,$lineHeight: null,$color: null) {
  font-family: $font_family_normal;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: ($fontSize / 10) + rem;
  line-height: ($lineHeight / 10) + rem;
  color: $color;
}
@mixin FontStyle( $fontWeight: null, $fontSize: null, $lineHeight: null, $color: null, $letterSpacing: null) {
	font-family: 'Pangea';
	font-style: normal;
	font-weight: $fontWeight;
	font-size: ($fontSize / 10)+rem;
	line-height: ($lineHeight / 10)+rem;
	color: $color;
	letter-spacing: $letterSpacing;
	
}
@mixin defaultFontStyleFestival($font-family:null, $fontSize: null, $lineHeight: null, $color: null, $letterSpacing: null) {
	font-family: $font-family;
	font-style: normal;
	font-weight: normal;
	font-size: ($fontSize / 10)+rem;
	line-height: ($lineHeight / 10)+rem;
	color: $color;
	letter-spacing: $letterSpacing;
}
// media queries
@mixin mediaQuery($canvas) {
  @if $canvas==desktop {
    @media only screen and (max-width: $desktop) {
      @content;
    }
  }
  @if $canvas==extraSmall {
    @media only screen and (max-width: $extraSmall) {
      @content;
    }
  }
  @if $canvas==small {
    @media only screen and (max-width: $small) {
      @content;
    }
  } @else if $canvas==medium {
    @media only screen and (max-width: $medium) {
      @content;
    }
  } @else if $canvas==large {
    @media only screen and (max-width: $large) {
      @content;
    }
  } @else if $canvas==extraLarge {
    @media only screen and (max-width: $extraLarge) {
      @content;
    }
  } @else {
    @media only screen and (max-width: $canvas) {
      @content;
    }
  }
}
@mixin mediaQuery ($point) {
  @media screen and (max-width:$point) {
      @content;
  }
}
@mixin minmediaQuery($point) {
  @media screen and (min-width: $point) {
    @content;
  }
}
// Border Bottom - 2px solid black
@mixin border_bottom_black {
  border-bottom: 2px solid $black;
}

// Flex
@mixin flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@mixin align_center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@mixin justify_center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@mixin justify_between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin flex_align_center {
  @include flex;
  @include align_center;
}
@mixin flex_justify_between {
  @include flex;
  @include justify_between;
}

@mixin flex_justify_center {
  @include flex;
  @include justify_center;
}

@mixin flex_center_center {
  @include flex;
  @include align_center;
  @include justify_center;
}
@mixin flex_center_between {
  @include flex;
  @include align_center;
  @include justify_between;
}

// Flex Direction
@mixin flex_dir_column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@mixin flex_dir_column_rev {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
@mixin flex_dir_row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex_column {
  @include flex;
  @include flex_dir_column;
}

@mixin f_wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@mixin flex_wrap {
  @include flex;
  @include f_wrap;
}

@mixin flex_shrink {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
/* For Fonts */
@mixin defaultFont(
  $fontWeight: null,
  $fontSize: null,
  $lineHeight: null,
  $color: null,
  $letterSpacing: null
) {
  font-family: $font_family_normal;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: ($fontSize / 10) + rem;
  line-height: ($lineHeight / 10) + rem;
  color: $color;
  letter-spacing: $letterSpacing;
}

@mixin mediaFontStyle($fontSize: null, $lineHeight: null) {
  font-size: ($fontSize / 10) + rem;
  line-height: ($lineHeight / 10) + rem;
}

/* For Fonts */
@mixin fontStyle(
  $fontWeight: null,
  $fontSize: null,
  $lineHeight: null,
  $color: null,
  $letterSpacing: null
) {
  font-family: $font_family_normal;
  font-style: normal;
  font-weight: $fontWeight;
  font-size: ($fontSize / 10) + rem;
  line-height: ($lineHeight / 10) + rem;
  color: $color;
  letter-spacing: $letterSpacing;
}

// Transition
@mixin transitionStyle($time: null, $function: null) {
  -webkit-transition: $time + s $function;
  -o-transition: $time + s $function;
  transition: $time + s $function;
}

// Content
@mixin content {
  content: "";
  position: absolute;
}

// Width and height
@mixin square($pixels: null) {
  width: $pixels;
  height: $pixels;
}

// Button Reset
@mixin buttonReset {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}

// Text Overflow Ellipse
@mixin LimitLineText($line: null) {
  -webkit-line-clamp: $line;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

//sd_main_panel_cta_btn
@mixin sd_main_panel_cta_btn {
  
    // width: 100%;
    @include mediaQuery($mobile) {
      text-align: center;
    }
    
    .sd_panel_cta_btn
    {
      margin-bottom: 56px;
    }
    a {
      @include fontStyle(500, 22, 80, $black, -0.528px);
      border: 2px solid;
      display: inline-block;
      min-width: 230px;
      padding: 0 20px;
      text-align: center;
      @include mediaQuery($extraLarge) {
        line-height: 70px;
      }
      @include mediaQuery($Largemobile) {
        font-size: 22px;
        line-height: 60px;
      }
      @include mediaQuery($mobile) {
        font-size: 18px;
        line-height: 60px;
        min-width: 137px;
      }
      @include mediaQuery($smallMobile) {
        line-height: 50px;
      }
      &:hover {
        background-color: $black;
        color: $white;
      }
    }
 
}
// RTF
@mixin rtf_content {
  * {
    font-size: 16px !important;
    color: $black !important;
  }
  a {
    color: $purple !important;
    * {
      color: $purple !important;
    }
  }
  li {
    padding-bottom: 10px;
  }
  ol {
    li {
      margin-left: 30px !important;
    }
  }
  ul {
    li {
      margin-left: 20px !important;
    }
  }
}
@mixin  serachbox {
	outline: none;
    font-family: $font_family_normal;
    font-style: normal;
	@include defaultFontStyleFestival($font_family_normal, 16, 20, $black);
    color: $black !important;
    padding: 17px 13px 14px;
	width: 100%;
	max-width: 440px;
	background: none;
    border: 1px solid $black;
}
// white space for pre tag
@mixin white_space_pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
}
