.sd_signin_section {
  margin-bottom: 100px;
  position: relative;
  z-index: 105;
  @include mediaQuery($mobile) {
    margin-bottom: 50px;
  }
}
.sd_signin_wpr {
  max-width: 692px;
  width: 100%;
  //margin: -280px 90px 250px auto;
  margin: -60px 90px 93px auto;
  background: $white;
  -webkit-box-shadow: 0px 0px 25px 1px rgba($black, 0.2);
  box-shadow: 0px 0px 25px 1px rgba($black, 0.2);
  padding: 47px 126px 88px;
  @include mediaQuery($desktop) {
    padding: 40px 80px 60px;
    margin: -200px 90px 250px auto;
  }
  @include mediaQuery($extraLarge) {
    margin: -100px 90px 200px auto;
  }
  @include mediaQuery($iPad) {
    padding: 30px 50px;
    margin: -100px 90px 150px auto;
  }
  @include mediaQuery($mobile) {
    padding: 20px;
    margin: -50px 90px 100px auto;
  }

  h2 {
    margin-bottom: 20px;
    @include defaultFontStyle(400, 50, 51, $black);
    letter-spacing: -1.2px;
    text-align: center;
    @include mediaQuery($large) {
      @include defaultFontStyle(400, 40, 41, $black);
    }
    @include mediaQuery($iPad) {
      @include defaultFontStyle(400, 30, 31, $black);
    }
    @include mediaQuery($mobile) {
      @include defaultFontStyle(400, 22, 31, $black);
      margin-bottom: 0;
    }
  }

  .sd_signin_form {
    padding-top: 80px;
    @include mediaQuery($extraLarge) {
      padding-top: 30px;
    }
    .sd_form_group {
      margin-bottom: 23px;
      @include mediaQuery($iPad) {
        margin-bottom: 15px;
      }
      label {
        @include defaultFontStyle(400, 25, 25, #555);
        margin-bottom: 14px;
        letter-spacing: -0.6px;
        @include mediaQuery($iPad) {
          @include defaultFontStyle(400, 20, 25, #555);
          margin-bottom: 10px;
        }
        @include mediaQuery($mobile) {
          @include defaultFontStyle(400, 14, 20, $black);
        }
      }
      input {
        @include defaultFontStyle(300, 25, 25, $black);
        width: 100%;
        background: #cdcdcd;
        border: 0;
        padding: 12px 21px;
        border-radius: 5px;
        letter-spacing: -0.6px;
        @include mediaQuery($iPad) {
          @include defaultFontStyle(300, 20, 25, $black);
        }
        @include mediaQuery($mobile) {
          @include defaultFontStyle(400, 14, 20, $black);
        }
      }
      span {
        @include defaultFontStyle(400, 14, 19, $red);
        @include mediaQuery($mobile) {
          @include defaultFontStyle(400, 12, 16, $red);
        }
      }
    }
    .sd_btn_group {
      margin-top: 74px;
      @include mediaQuery($large) {
        margin-top: 30px;
      }
      button {
        @include defaultFontStyle(500, 25, 25, $black);
        max-width: calc(50% - 23px);
        width: 100%;
        cursor: pointer;
        letter-spacing: -0.6px;
        -webkit-transition: 0.3s ease;
        -o-transition: 0.3s ease;
        transition: 0.3s ease;
        background: $whisper;
        border: none;
        padding: 29px 20px;
        text-transform: capitalize;
        @include mediaQuery($iPad) {
          padding: 10px 20px;
          @include defaultFontStyle(700, 18, 24, $black);
        }
        @include mediaQuery($mobile) {
          @include defaultFontStyle(700, 16, 20, $black);
        }
      }
      .sd_gray_btn {
        &:hover {
          color: $white;
          background: $purple;
        }
      }
      .sd_orange_btn {
        background: $yellow;
        &:hover {
          color: $white;
          background: $tomato;
        }
        &:disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}
