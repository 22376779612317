.sd_chase_event {
  background: $lightGreen;
  .sd_simplelp_section {
    .sd_simplelp_left.sd_simplelp_heading {
      max-width: 925px;
      h3 {
        max-width: 640px;
      }
    }
  }

  &.sd_chase_event_page {
    .sd_simplelp_section {
      margin-top: 41px;
      .sd_simplelp_left.sd_simplelp_heading {
        margin-bottom: 20px;
        h1 {
          margin-bottom: 12px;
        }
        h3 {
          margin-top: 58px;
        }
      }
    }
    .sd_event_details {
      margin-top: -4px;
    }
  }
  .sd_full_image {
    margin: 30px 0;
  }

  // mock events section
  .sd_mockevents_details {
    .sd_simplelp_left {
      max-width: 530px;
      @include mediaQuery($extraLarge) {
        max-width: 100%;
      }
    }
    .sd_simplelp_right {
      max-width: calc(100% - 530px);
      @include mediaQuery($extraLarge) {
        max-width: 100%;
      }
      .sd_right_inner {
        max-width: 875px;
        @include mediaQuery($extraLarge) {
          max-width: 100%;
        }
        .sd_speakers_section {
          margin: 0 -12px;
          .sd_speakers_inner {
            width: calc(25% - 24px);
            margin: 0 12px;
            margin-bottom: 20px;
            @include mediaQuery($iPad) {
              width: calc(50% - 24px);
            }
            @include mediaQuery($mobile) {
              width: 100%;
            }
            .sd_speakers_image {
              height: 200px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .sd_author_name {
              margin-top: 10px;
              letter-spacing: -0.38px;
              @include defaultFontStyle(500, 16, 23, $black);
            }
            .sd_author_bio {
              margin-top: 5px;
              letter-spacing: -0.38px;
              @include defaultFontStyle(300, 14, 21, $black);
            }
          }
        }
      }
    }
  }
}

// gallery section
.sd_gallery_section {
  margin-top: -100px;
  margin-bottom: 100px;
  @include mediaQuery($extraLarge) {
    margin-top: 0;
  }
  .sd_gallery_inner {
    .sd_gallery_left {
      max-width: 666px;
      margin-top: 70px;
      width: 100%;
      @include mediaQuery($extraLarge) {
        max-width: 100%;
        margin-bottom: 30px;
      }
      h2 {
        font-size: 6rem;
        line-height: 6rem;
        letter-spacing: -1.44px;
        @include mediaQuery($iPad) {
          font-size: 4rem;
          line-height: 4rem;
        }
        @include mediaQuery($mobile) {
          font-size: 3rem;
          line-height: 3rem;
        }
      }
      .sd_btn {
        margin-top: 20px;
        min-width: 220px;
      }
    }
    .sd_gallery_right {
      max-width: calc(100% - 666px);
      width: 100%;
      @include mediaQuery($extraLarge) {
        max-width: 100%;
      }
      .sd_gallery_image {
        img {
          max-width: 680px;
          max-height: 565px;
          width: 100%;
          height: 100%;
          margin: auto;
          object-fit: contain;
        }
      }
    }
  }
}
