.sd_search_section
{
    padding-bottom: 130px;
    @include mediaQuery($ipad){
        padding-bottom: 0;
    }
    .sd_search_banner
    {
        padding: 84px 0 89px 0px;
        background-image:url('/program/images/Searchbg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%; 
        background-position: 0px -53px;
        @include mediaQuery($large){
            background-image:url('/program/images/search_mobile_bg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: 0px 0px;

        }
        .sd_serach_box
        {
            text-align: center;
            form{
                position: relative;
                width: 100%;
                max-width: 440px;
                margin: 0 auto;
                @include mediaQuery($large){
                    max-width: 330px;
                }
            }
            input
            {
                @include serachbox;
                &::placeholder{
                    color: $black;
                }
            }
            button
            {
                background: none;
                border: none;
                position: absolute;
                top: 17px;
                right: 8px;
                img{
                    margin-left: 12px;
                    width: 18px;
                }
            }
            .sd_enter_serach
            {
                cursor: pointer;
            }
        }
    }
    .sd_search_filter
    {
        @include defaultFontStyleFestival($font_family_normal, 18, 20, $black);
        @include mediaQuery($large){
            display: none;
        }
    }
    .sd_sub_title
    {
        @include mediaQuery($large){
            display: none;
        }
        .sd_filters_options
        {
            @include defaultFontStyleFestival($font_family_normal, 22, 18, #808080);
            width: 100%;
            justify-content: space-between;
            padding: 20px 0;
            @include border_bottom_black;
            li
            {
               list-style: none;
               cursor: pointer;
               &.active
               {
                p{
                    // @include defaultFontStyleFestival($font_family_bold, 22, 18, #04AC80); 
                
                    @include FontStyle(700,22, 18, #04AC80);
                   
                    // color: #04AC80 !important;
                 }
               }
            }
        }
    }
    .sd_search_Details
    {
        // padding: 36px 0 111px 0;
        width: 50%;
       
        .sd_main_details
        {
            max-width: 713px;
            width: 100%;
            margin-bottom: 22px;
           
            .sd_category_title{
                p{
                    @include FontStyle(700, 18, 22, #C2C2C2,-0.43px);
                }
            }
            .sd_category_details
            {
                margin-bottom: 10px;
                h3{
                    @include defaultFontStyleFestival($font_family_normal, 30, 32, $black,-0.72px);
                    cursor: pointer;
                    a{
                        color: $black !important;
                    }
                }
           }
           .sd_category_desc{
            @include FontStyle(400, 16, 23, $black,-0.38px);
           } 
              
        }
    }
    .sd_no_filters_found {
        max-width: 974px;
        width: 100%;
        margin: 45px auto 100px;
        text-align: center;
    }
}

.sd_filter
{
    display: flex;
    flex-wrap: wrap;
    // @include mediaQuery($large){
    //     display: none;
	// }
    .sd_search_Details
    {
        // width: 50%;
        width: calc(50% - 15px);
        margin: 36px 15px 50px 0;
        @include mediaQuery($large){
            width: 100%;
            margin: 18px 0 18px 0;
        }
    }
}
.serach_modal
{
    // position: relative;
    position: fixed;
    width: 100%;
    height: auto;
    left: 0;
    top: 0px;
    // background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    .sd_main_serach
    {
        display: flex;
        position: absolute;
        justify-content: space-between;
        max-width: 500px;
        width: 100%;
        top: 14px;
        right: 9px;
        align-items: center;
        z-index: 7;
        @include mediaQuery($mobileLarge){
            min-width: 100px !important;
            width: 96%;
            padding: 0 15px ;
        }
        .sd_serach_box
        {
            min-width: 440px;
            @include mediaQuery($mobileLarge){
                min-width: 330px;
            }
            @include mediaQuery($mobile){
                min-width: 280px;
            }
            form{
                position: relative;
                
                input
                {
                    @include serachbox;
                    background: $white;
                    &::placeholder{
                        color: $black;
                    }
                }
                .sd_error_msg
                {
                    position: absolute;
                    top: 20px;
                    right: 40px;
                    @include defaultFontStyleFestival($font_family_normal, 12, 12, $resetcolor);
                    @include mediaQuery($mobileLarge){
                        top: 65px;
                        right: 54px;
                    }
                   

                }
                .sd_enter_serach
                {
                    background: none;
                    border: none;
                    position: absolute;
                    top: 17px;
                    right: 8px;
                    cursor: pointer;
                    img{
                        margin-left: 12px;
                        width: 18px;
                        // cursor: pointer;
                    }
                }
            }
        }
        .sd_close_popup{
            @include square(28px);
            @include buttonReset;
            // @include flex_justify_center;
            display: flex;
            
            span{
                display: block;
                width: 2px;
                background: $black !important;
                height: 37px;
                &:first-child{
                    transform: rotate(-45deg) translate(4px, -3px);
                }
                &:last-child{
                    transform: rotate(45deg) translate(-4px, -3px);
                }
            }
        }
    }
}
.overlay_header
{
    position: fixed;
    width: 100%;
    height: 78px;
    right: 0;
    top: 0px;
    // background: rgba(0,0,0,0.4);
    background: $white !important;    
    // background: rgba(256,256,256,1);
    z-index: 6;
    // @include mediaQuery($desktop){
    //     width: 100%;
    //     height: 100%;
    // }
}
.search_result{
    margin-bottom: 50px;
    .accordion__button{
        position: relative;
        &:before{
            position: absolute;
            right: 8px;
            top:23px;
            transform: rotate(45deg);
        }
        &[aria-expanded='true'], &[aria-selected='true']{
            background-color: $lygreen;
            color: $white;
            p{
                color: $white;
            }
            &::before{
                transform: rotate(225deg);
                top: 25px;
            }
        }
    }
    .accordion__panel{
        padding: 0;
    }
}

