// @font-face {
//   font-family: "La Nord V2.1";
//   src: url("/fonts/LaNordV21-Medium.woff2") format("woff2"),
//     url("/fonts/LaNordV21-Medium.woff") format("woff");
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "La Nord V2.1";
//   src: url("/fonts/LaNordV21-Light.woff2") format("woff2"),
//     url("/fonts/LaNordV21-Light.woff") format("woff");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "La Nord V2.1";
//   src: url("/fonts/LaNordV21-Bold.woff2") format("woff2"),
//     url("/fonts/LaNordV21-Bold.woff") format("woff");
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "La Nord V2.1 Semi";
//   src: url("/fonts/LaNordV21-SemiLight.woff2") format("woff2"),
//     url("/fonts/LaNordV21-SemiLight.woff") format("woff");
//   font-weight: 200;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "La Nord V2.1";
//   src: url("/fonts/LaNordV21-Regular.woff2") format("woff2"),
//     url("/fonts/LaNordV21-Regular.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
	font-family: $font_family_normal;
	src:url('/fonts/Pangea-Regular.eot'); 
	src:url('/fonts/Pangea-Regular.ttf') format('truetype'),url('/fonts/Pangea-Regular.woff') format('woff'),  url('/fonts/Pangea-Regular.svg') format('svg');
	//src: url("/fonts/Pangea-Regular.woff") format("woff");
	// src: url("/fonts/Pangea-Regular.woff2") format("woff2");
    font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: $font_family_normal;
	src:url('/fonts/Pangea-Regular.eot'); 
	src:url('/fonts/Pangea-Regular.ttf') format('truetype') , url('/fonts/Pangea-Regular.woff') format('woff'), url('/fonts/Pangea-Regular.svg') format('svg');
    font-style: normal;
	font-display: swap;
}
@font-face {
    font-family: "Pangea_bold";
	src: url('/fonts/Pangea-Bold.eot');
	src: local('☺'), url('/fonts/Pangea-Bold.ttf') format('truetype'), url('/fonts/Pangea-Bold.woff') format('woff'),  url('/fonts/Pangea-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Pangea_medium";
	src: url('/fonts/Pangea-Medium.eot');
	src: local('☺'), url('/fonts/Pangea-Medium.ttf') format('truetype'),url('/fonts/Pangea-Medium.woff') format('woff'),  url('/fonts/Pangea-Medium.svg') format('svg');
	// src: url("/fonts/Pangea-Medium.woff2") format("woff2");
	font-weight: normal;
	// src: url("/fonts/Pangea-Medium.woff") format("woff");
	// src: url("/fonts/Pangea-Medium.woff2") format("woff2");
	font-display: swap;
}
@font-face {
	font-family: "Pangea_light";
	src: url('/fonts/Pangea-Light.eot');
	src: local('☺'), url('/fonts/Pangea-Light.ttf') format('truetype'),url('/fonts/Pangea-Light.woff') format('woff'),  url('/fonts/Pangea-Light.svg') format('svg');
	font-weight: normal;
	// src: url("/fonts/Pangea-Light.woff2") format("woff2");
	font-display: swap;
}


@font-face {
    font-family: "Trade Gothic Next LT Pro";
    // src:url('../fonts/Pangea-Regular.eot'); 
    src:url('./fonts/TradeGothicNextLTPro-Rg.woff') format('woff '),url('./fonts/TradeGothicNextLTPro-Rg.ttf') format('truetype');
    // src:url('../fonts/Pangea-Regular.ttf') format('truetype');
    // src: url("../fonts/Pangea-Regular.woff") format("woff");
    // src: url("../fonts/Pangea-Regular.woff2") format("woff2");
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Trade Gothic Next LT Pro bold";
    // src: url('../fonts/Pangea-Bold.eot');
    src:  url('./fonts/TradeGothicNextLTPro-Bd.woff') format('woff'),url('./fonts/TradeGothicNextLTPro-Bd.ttf') format('truetype');
    // src:url('../fonts/Pangea-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Trade Gothic Next LT Pro medium";
    // src:url('../fonts/Pangea-Regular.eot'); 
    src:url('./fonts/TradeGothicNextLTPro-Rg.woff') format('woff '),url('./fonts/TradeGothicNextLTPro-Rg.ttf') format('truetype');
    // src:url('../fonts/Pangea-Regular.ttf') format('truetype');
    // src: url("../fonts/Pangea-Regular.woff") format("woff");
    // src: url("../fonts/Pangea-Regular.woff2") format("woff2");
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Trade Gothic Next LT Pro light";
    // src: url('../fonts/Pangea-Bold.eot');
    src:  url('./fonts/TradeGothicNextLTPro-Lt.woff') format('woff'),url('./fonts/TradeGothicNextLTPro-Lt.ttf') format('truetype');
    // src:url('../fonts/Pangea-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

h1 {
  @include fontStyle(400, 50, 75, $black, -1.92px);
  font-weight: normal;
  @include mediaQuery($large) {
    @include mediaFontStyle(40, 60);
  }
  @include mediaQuery($mobile) {
    @include mediaFontStyle(30, 40);
  }
}
h2 {
  @include fontStyle(400, 40, 40, $black, -0.96px);
  @include mediaQuery($large) {
    @include mediaFontStyle(30, 40);
  }
  @include mediaQuery($mobile) {
    @include mediaFontStyle(20, 30);
  }
}
h3 {
  @include fontStyle(400, 22, 27, $black);
}
p {
  @include fontStyle(400, 16, 23, $black, -0.38px);
  @include mediaQuery($smallMobile) {
    @include mediaFontStyle(14, 20);
  }
}
