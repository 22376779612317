.sd_datebar {
  padding: 16px;
  // border-top: 1px solid rgba(0, 0, 0, 0.35);
  // border-left: 1px solid rgba(0, 0, 0, 0.35);
  span {
    letter-spacing: -0.34px;
    text-align: center;
    @include defaultFontStyle(300, 14, 14, $white);
  }
  p {
    letter-spacing: -0.72px;
    text-align: center;
    @include defaultFontStyle(500, 30, 30, $white);
    margin-top: 5px;
  }
  &_purple {
    background: $purple;
  }
  &_pink {
    background: $pink;
  }
  &_lightGreen {
    background: $lightGreen;
    span,
    p {
      color: $black;
    }
  }
  &_magenta {
    background: $magenta;
    span,
    p {
      color: $white;
    }
  }
}
