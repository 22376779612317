.sd_ScreeningView_header {
  padding: 52px 0 73px;
  width: 739px;
  @include mediaQuery($iPad) {
    padding: 45px 0 52px;
    width: 100%;
  }
  @include mediaQuery($Largemobile) {
    padding: 40px 0 40px;
  }
  @include mediaQuery($Largemobile) {
    padding: 35px 0 40px;
  }
  h1 {
    @include fontStyle(400, 60, 60, $black, -1.44px);
    padding-bottom: 5px;
    @include mediaQuery($extraLarge) {
      font-size: 50px;
      line-height: 4rem;
    }
    @include mediaQuery($iPad) {
      font-size: 45px;
      padding-bottom: 10px;
    }
    @include mediaQuery($Largemobile) {
      font-size: 40px;
      padding-bottom: 10px;
    }
    @include mediaQuery($mobile) {
      font-size: 33px;
    }
    @include mediaQuery($mobileSmall) {
      font-size: 30px;
    }
    @include mediaQuery($smallMobile) {
      line-height: 30px;
    }
  }

  .sd_ScreeningView_inner {
    display: flex;
    align-items: center;
    margin-bottom: 33px;
    @include mediaQuery($extraLarge) {
      margin-bottom: 20px;
    }
    @include mediaQuery($iPad) {
      margin-bottom: 15px;
    }
    .sd_ScreeningView_img {
      width: 103px;
      img {
        width: 100%;
      }
    }
    p {
      padding-bottom: 0 !important;
      padding-right: 10px;
      @include fontStyle(400, 10, 23, $black, -0.24px);
    }
  }

  .sd_Schedule {
    display: flex;
    padding-bottom: 3px;
    @include mediaQuery($smallMobile) {
      flex-wrap: wrap;
    }
    @include mediaQuery($iPad) {
      padding: 0;
    }
    h3 {
      @include fontStyle(500, 22, 23, $black, -0.528px);
      span {
        padding-left: 15px;
        @include mediaQuery($mobileSmall) {
          padding-left: 5px;
        }
      }
      @include mediaQuery($Largemobile) {
        font-size: 20px;
      }
      @include mediaQuery($mobile) {
        font-size: 15px;
      }
    }
    p {
      display: flex;
      align-items: center;
      padding: 0;
      img {
        padding: 0 6px 0 20px;
        @include mediaQuery($mobileSmall) {
          padding: 0 6px 0 10px;
        }
      }
      @include fontStyle(500, 10, 10, $white, -0.24px);
      @include mediaQuery($mobile) {
        font-size: 9px;
        padding-bottom: 10px;
      }
    }
    button {
      display: flex;
      align-items: center;
      padding: 0;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      img {
        padding: 0 6px 0 20px;
        @include mediaQuery($mobileSmall) {
          padding: 0 6px 0 10px;
        }
      }
      @include fontStyle(500, 10, 10, $white, -0.24px);
      @include mediaQuery($mobile) {
        font-size: 9px;
        padding-bottom: 10px;
      }
    }
  }

  a {
    margin-top: 15px;
    @include fontStyle(500, 22, 80, $black, -0.528px);
    border: 2px solid;
    min-width: 230px;
    padding: 0 20px;
    display: inline-block;
    text-align: center;
    &:hover {
      background-color: $black;
      color: white;
      border-color: $black;
    }
    @include mediaQuery($extraLarge) {
      line-height: 70px;
    }
    @include mediaQuery($Largemobile) {
      font-size: 22px;
      line-height: 60px;
    }
    @include mediaQuery($mobile) {
      font-size: 18px;
      line-height: 60px;
    }
    @include mediaQuery($smallMobile) {
      line-height: 50px;
    }
  }
}

.sd_Event_Details_panal {
  padding: 31px 0 0px;
  @include mediaQuery($Largemobile) {
    padding: 20px 0 0px;
  }
  @include mediaQuery($mobile) {
    padding: 15px 0 0px;
  }
  .sd_event_des {
    display: flex;
    padding-bottom: 55px;
    @include mediaQuery($iPad) {
      padding-bottom: 0;
    }
    @include mediaQuery($iPad) {
      flex-direction: column;
    }
    @include mediaQuery($Largemobile) {
      padding-bottom: 10px;
    }
    @include mediaQuery($mobileSmall) {
      padding-bottom: 30px;
    }
    .sd_event_detail_left {
      width: 50%;
      padding-right: 40px;
      box-sizing: border-box;
      @include mediaQuery($extraLarge) {
        padding-right: 25px;
      }
      @include mediaQuery($iPad) {
        width: 100%;
        padding-right: 0;
      }
      h2 {
        @include fontStyle(500, 22, 60, $black, -0.528px);
        @include mediaQuery($smallMobile) {
          font-size: 20px;
        }
      }
      p {
        @include fontStyle(300, 16, 23, $black, -0.384px);
        padding-bottom: 15px;
        @include mediaQuery($iPad) {
          font-size: 15px;
          padding-bottom: 0;
        }
      }
    }
    .sd_event_detail_right {
      width: 50%;
      padding-left: 40px;
      box-sizing: border-box;
      @include mediaQuery($extraLarge) {
        padding-left: 25px;
      }
      @include mediaQuery($iPad) {
        width: 100%;
        padding-left: 0;
      }
      h2 {
        @include fontStyle(500, 22, 60, $black, -0.528px);
        @include mediaQuery($smallMobile) {
          font-size: 20px;
        }
      }
      .sd_main_time {
        padding-bottom: 30px;
        @include mediaQuery($Largemobile) {
          padding-bottom: 10px;
        }
        .sd_detail_time {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          span {
            @include fontStyle(500, 16, 23, $black, -0.384px);
            width: 25%;
            @include mediaQuery($smallMobile) {
              width: 45%;
            }
          }
          h3 {
            @include fontStyle(300, 16, 23, $black, -0.384px);
            @include mediaQuery($smallMobile) {
              width: 50%;
            }
          }
        }
      }
      a {
        padding: 20px 75px;
        @include fontStyle(500, 22, 80, $black, -0.528px);
        @include mediaQuery($Largemobile) {
          padding: 20px 20px;
          font-size: 16px;
        }
        border: 2px solid;
        &:hover {
          background-color: $black;
          color: $white;
        }
      }
    }
  }
}

.sd_ScreeningView_Carousel {
  text-align: center;
  padding-top: 20px;
  @include mediaQuery($Largemobile) {
    padding-top: 5px;
  }
  .sd_ScreeningView_Carousel_inner {
    padding-bottom: 118px;
    @include mediaQuery($iPad) {
      padding-bottom: 30px;
    }
    h2 {
      @include fontStyle(400, 60, 60, $black, -1.44px);
      padding-bottom: 47px;
      @include mediaQuery($iPad) {
        font-size: 40px;
        padding-bottom: 40px;
      }
      @include mediaQuery($Largemobile) {
        font-size: 30px;
        line-height: 37px;
        padding-bottom: 0;
      }
      @include mediaQuery($mobile) {
        font-size: 25px;
        padding-bottom: 0;
      }
      @include mediaQuery($mobileSmall) {
        font-size: 20px;
        padding-bottom: 0;
      }
    }
    a {
      @include fontStyle(500, 22, 80, $black, -0.528px);
      border: 2px solid;
      min-width: 230px;
      padding: 0 20px;
      display: inline-block;
      &:hover {
        background: $black;
        color: $white;
        border-color: $black;
      }
      @include mediaQuery($extraLarge) {
        line-height: 70px;
      }
      @include mediaQuery($Largemobile) {
        font-size: 22px;
        line-height: 60px;
      }
      @include mediaQuery($mobile) {
        font-size: 18px;
        line-height: 60px;
      }
      @include mediaQuery($smallMobile) {
        line-height: 50px;
      }
    }
  }
}

.sd_video_sec {
  div {
    max-height: 949px;
    @include mediaQuery($extraLarge) {
      max-height: 800px;
    }
    @include mediaQuery($iPad) {
      max-height: 675px;
    }
    @include mediaQuery($iPad) {
      max-height: 100%;
      height: auto;
    }

    img {
      width: 100%;
      max-height: 949px;
      object-fit: cover;
      @include mediaQuery($extraLarge) {
        max-height: 800px;
      }
      @include mediaQuery($iPad) {
        max-height: 675px;
      }
      @include mediaQuery($iPad) {
        max-height: 100%;
      }
    }
  }
}

.sd_main_GeneralView_images {
  width: 1416px;
  margin: 0 auto;

  @include mediaQuery(1500px) {
    width: 100%;
    padding: 0 30px;
  }
  @include mediaQuery($Largemobile) {
    width: 100%;
    padding: 0 15px;
  }
  @include mediaQuery($mobile) {
    padding: 0 0px;
  }
  .slider-wrapper {
    max-height: 796px !important;
    @include mediaQuery($desktop) {
      max-height: 740px !important;
    }
    @include mediaQuery($extraLarge) {
      max-height: 545px !important;
    }
    @include mediaQuery($extraLarge) {
      max-height: 100% !important;
    }
  }
}

.video-react-controls-enabled {
  padding-top: 56.25% !important;
}

//FavoriteCard
.sd_FavoriteCard_sec {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 999;
  overflow: hidden;
  .sd_FavoriteCard_inner {
    max-width: 537px;
    margin: 0 auto;
    width: 100%;
    max-height: 100%;
    background: #fff;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    padding: 10px 40px 55px;
    border: none;
    .sd_FavoriteCard_des {
      padding: 50px 0 0;
      p {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.6rem;
        color: #000;
        letter-spacing: -0.38px;
        max-width: 405px;
        margin: 0 auto 15px;
        white-space: pre-wrap;
        a {
          text-transform: capitalize;
          color: #ff625a;
        }
      }
      .sd_FavoriteCard_button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 13px;
        button {
          font-style: normal;
          font-weight: 500;
          font-size: 1.65rem;
          line-height: 1.6rem;
          color: #000;
          letter-spacing: -.4px;
          max-width: 113px;
          width: 100%;
          padding: 17px 10px;
          border: none;
          cursor: pointer;
          transition: .2s linear;
          margin-right: 18px;
          outline: none;
        }
      }
    }
  }
}

