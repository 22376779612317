// Home Page
.sd_home_page {
  position: relative;
  .sd_header_section {
    @include mediaQuery($mobile) {
      padding-bottom: 75px;
    }
  }
  .sd_header {
    position: relative;
    // padding-bottom: 135px;
    background-color: $drakblue;
    background-image: url("/village/images/header.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 2px solid #000;
    // background-size: 100% 100%;
    //margin-top: -69px;
    @include mediaQuery($large) {
      margin-top: 0;
      padding-bottom: 30px;
    }
    @include mediaQuery($mobile) {
      background-image: url("/village/images/header_mobile_bg.png");
      margin-top: 7px;
      background-position: right top;
      background-size: unset;
    }
  }
  .sd_header_bottom {
    width: 100%;
  }
  .sd_simplelp_section {
    max-width: 1680px;
    margin: 0 auto;
  }
  // header-bg
  .sd_header_sec_section {
    // background-color: $purple;
    // border-bottom: 2px solid #000;
  }
  .sd_hero_header_inner_section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 5;
    .sd_header_sec {
      padding: 80px 00px 89px 0px;
      width: 100%;
      @include flex_justify_between;
      @include mediaQuery($desktop) {
        padding: 40px 0px 40px 0px;
        flex-direction: column;
      }
     
      .sd_simplelp_left {
        // width: 45%;
        max-width: 690px;
        width: 100%;
        //padding: 105px 50px 30px 0px;
        // padding: 80px 50px 89px 0px;
        @include mediaQuery($extraLarge) {
          padding: 75px 17px 30px 0px;
          width: 100%;
        }
        @include mediaQuery($iPad) {
          padding: 35px 0px 30px;
        }
        @include mediaQuery($Largemobile) {
          border: none;
          padding: 20px 0 0;
        }
        @include mediaQuery($mobile) {
          max-width: 284px;
          width: 100%;
        }
        p {
          margin-top: 0;
          @include defaultFontStyle(400, 22, 32, $black);
          @include mediaQuery($mobile) {
            line-height: 2.2rem;
          }
        }
        h1 {
         
          @include defaultFontStyle(400, 80, 75, $black);
          letter-spacing: -1.92px;
          padding-bottom: 30px;

          @include mediaQuery(1530px) {
            @include defaultFontStyle(400, 65, 75, $black);
          }
          @include mediaQuery($extraLarge) {
            @include defaultFontStyle(400, 53, 55, $black);
          }
          @include mediaQuery($iPad) {
            max-width: 220px;
            width: 100%;
            @include defaultFontStyle(400, 40, 45, $black);
            padding-bottom: 7px;
          }
        
        }
        .sd_home_cta_button {
          @include defaultFontStyle(500, 22, 36, $black);
          letter-spacing: -0.53px;
          background: none;
          border: none;
          outline: none;
          padding: 0;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          cursor: pointer;
          @include mediaQuery($iPad) {
            @include defaultFontStyle(500, 18, 30, $black);
          }
          @include mediaQuery($mobile) {
            @include defaultFontStyle(500, 16, 26, $black);
          }
          img {
            width: 14px;
            margin-left: 14px;
          }
        }
      }
      .sd_simplelp_right_inner {
        width: 60%;
        //padding: 105px 0 10px 50px;
        // border-left: 2px solid $black;
        @include mediaQuery($extraLarge) {
          padding: 75px 0 30px 20px;
          width: 100%;
        }
        @include mediaQuery($large) {
          border: none;
          padding: 0 0 20px;
        }
        @include mediaQuery($iPad) {
         display: none;
        }
        .slider-wrapper {
          max-height: 511px !important;
          @include mediaQuery(1500px) {
            max-height: 100%;
            height: auto !important;
          }
        }
        .carousel .slide img {
          max-height: 511px;
          object-fit: cover;
          @include mediaQuery($Largemobile) {
            max-height: 100%;
            height: auto !important;
          }
        }
        .sd_homepage_carousel {
          .slider-wrapper {
            max-height: 511px !important;
            @include mediaQuery(1500px) {
              height: auto !important;
            }
          }
          .video-react-video {
            width: 100% !important;
            object-fit: cover !important;
          }
          .carousel .slide img {
            max-height: 511px;
            object-fit: cover;
            @include mediaQuery($Largemobile) {
              max-height: 100%;
              height: auto !important;
            }
          }
        }
      }
      .sd_play_button {
        position: absolute;
        top: 50%;
        left: 47%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        cursor: pointer;
        @include mediaQuery($desktop) {
          left: 50%;
        }
        @include mediaQuery($extraLarge) {
          top: 60%;
          img {
            width: 50px;
          }
        }
        img {
          @include mediaQuery($mobile) {
            width: 30px;
          }
        }
      }
    }
    .sd_header_title {
      max-width: 250px;
      margin: 0 43px 0 auto;
      padding-bottom: 30px;
      @include mediaQuery($mobile) {
        max-width: 100%;
        padding-bottom: 10px;
      }
      h2 {
        @include defaultFontStyle(400, 40, 35, $white);
        text-align: right;
        @include mediaQuery($extraLarge) {
          @include defaultFontStyle(400, 30, 35, $white);
        }
        @include mediaQuery($iPad) {
          @include defaultFontStyle(400, 26, 35, $white);
        }
        @include mediaQuery($mobile) {
          @include defaultFontStyle(400, 20, 32, $white);
        }
      }
    }
  }
  .sd_header_bg_layer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

// Medium Cards Section
.slick-slider {
  padding-top: 25px;
  @include mediaQuery($iPad) {
    padding-top: 5px;
  }
  @include mediaQuery($mobileSmall) {
    padding-top: 15px;
  }
  @include mediaQuery($smallMobile) {
    padding-top: 10px;
  }
}

// sd_Mainstreet_block
.sd_Mainstreet {
  margin-top: 16px;
  @include mediaQuery($iPad) {
    padding: 0;
  }
  border-top: 2px solid $black;
  .sd_Mainstreet_sec {
   
    @include mediaQuery(1493px) {
      width: 100%;
    }
    @include mediaQuery($desktop) {
      width: 100%;
      // padding: 0 20px;
    }
    @include mediaQuery($iPad) {
      padding: 0;
    }
    .sd_Mainstreet_container {
      // max-width: 1478px;
      // margin: 0 auto;
      @include mediaQuery($desktop) {
        width: 100%;
      }
      .sd_feature_inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        @include mediaQuery($iPad) {
          flex-direction: column;
        }
        .sd_feature_inner_sec {
          padding: 60px 48px;
          text-align: center;
          width: 100%;
          // border-top: 2px solid $black;
          // border-right: 2px solid $black;
          @include mediaQuery(1450px) {
            width: 100%;
          }
          @include mediaQuery($desktop) {
            padding: 20px 0px;
            width: 100%;
            
          }
          @include mediaQuery($iPad) {
            border: 0;
            border-top: 2px solid;
            border-bottom: 2px solid;
          }
          &:first-child {
            width: 50%;
            border: none;
            border-right: 2px solid;
            // padding: 20px 48px 20px 0;
            background-color: $darkpink;
            @include mediaQuery($desktop) {
              padding: 20px 0;
              width: 100%;
              
            }
            @include mediaQuery($iPad) {
              border-right: 0;
              padding: 20px 10px 20px 0;
              border-bottom: 2px solid $black;
            }
            @include mediaQuery($Largemobile) {
              padding: 50px 0;
            }
          }
          &:last-child {
            width: 50%;
            border: none;
            padding: 60px 48px;
            background-color: $lygreen;
            @include mediaQuery($desktop) {
              padding: 20px 0;
              width: 100%;
            }
            @include mediaQuery($iPad) {
              padding: 20px 0 20px 10px;
            }
            @include mediaQuery($Largemobile) {
              padding: 50px 0;
            }
          }
          a {
            text-align: center;
            @include defaultFontStyle(normal, 50, 41, $black);
            letter-spacing: -1.2px;
          }
          p {
            padding: 10px 0 0;
            @include defaultFontStyle(normal, 26, 24, $black);
            letter-spacing: -0.432px;
            @include mediaQuery($desktop) {
              font-size: 18px;
              padding: 10px 10px;
            }
            @include mediaQuery($iPad) {
              line-height: 21px;
            }
            @include mediaQuery($Largemobile) {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}

// all event
.sd_home_presention_sec {
  // .sd_home_presention {
  //   background-image: url("../../images/featured.png");
  //   background-position: center top;
  //   background-repeat: no-repeat;
  //   padding: 71px;
  //   background-size: cover;
  //   @include mediaQuery($desktop) {
  //     padding: 50px;
  //   }
  //   @include mediaQuery($Largemobile) {
  //     padding: 24px;
  //   }
  //   @include mediaQuery($mobile) {
  //     background: none;
  //     padding: 0;
  //   }
  // }
  .sd_home_presention_sponsor {
    // background-color: #ff625a;
    @include mediaQuery($mobile) {
      padding-top: 20px;
    }
    .sd_presention_sponsor {
      //border-bottom: 2px solid $black;
      //padding-bottom: 32px;
    }
    .sd_container {
      max-width: 1510px;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;
      .sd_full_calendar {
        // display: flex;
        align-items: center;
        padding-bottom: 35px;
        @include mediaQuery(1493px) {
          padding: 0 20px 35px;
        }
        @include mediaQuery($desktop) {
          padding: 0 00px 35px;
        }
        @include mediaQuery($extraLarge) {
          padding: 0 00px 20px;
        }
        @include mediaQuery($Largemobile) {
          flex-wrap: wrap;
          padding: 0 00px 20px;
        }
        @include mediaQuery($mobile) {
          padding: 0 00px 20px;
        }
        @include mediaQuery($smallMobile) {
          padding: 0 00px 20px;
        }
        .sd_calendar_title {
          padding-right: 73px;
          @include mediaQuery(1700px) {
           padding-left: 20px;
          }
          @include mediaQuery($extraLarge) {
           padding-left: 0;
          }
          @include mediaQuery($iPad) {
            padding-right: 50px;
          }
          @include mediaQuery($Largemobile) {
            padding-right: 0;
          }
          h2 {
            text-transform: uppercase;
            @include defaultFontStyle(400, 40, 40, $black);
            letter-spacing: -0.96px;
            @include mediaQuery($large) {
              font-size: 3rem;
              line-height: 4rem;
            }
            @include mediaQuery($extraLarge) {
              font-size: 30px;
            }
            @include mediaQuery($iPad) {
              font-size: 28px;
            }
            @include mediaQuery($belowiPad) {
              width: 100%;
              text-align: center;
              margin-bottom: 15px;
            }
            @include mediaQuery($mobileSmall) {
              font-size: 28px;
            }
            @include mediaQuery($smallMobile) {
              font-size: 22px;
            }
          }
        }
        .sd_calender_right {
          .sd_calendar_title_right {
            cursor: pointer;
            display: flex;
            align-items: center;
            .sd_calendar_sub_title {
              padding-right: 10px;
              h3 {
                text-transform: uppercase;
                @include defaultFontStyle(500, 22, 22, $black);
                letter-spacing: -0.528px;
              }
              p {
                @include defaultFontStyle(500, 12, 12, $black);
              }
            }
            .sd_calendar_images {
              width: 24px;
              img {
                width: 100%;
              }
            }
          }
          .sd_calendar_checkbox {
            padding-top: 12px;
            .sd_calendar_checkbox_inner {
              display: flex;
              align-items: center;
              .form-group {
                padding-right: 40px;
                @include mediaQuery($iPad) {
                  padding-right: 10px;
                }
                @include mediaQuery($belowiPad) {
                  padding-right: 20px;
                }
                @include mediaQuery($mobile) {
                  padding-right: 5px;
                }
                label {
                  text-transform: capitalize;
                  @include defaultFontStyle(300, 20, 26, $black);
                  letter-spacing: -0.48px;
                  position: relative;
                  display: block;
                  cursor: pointer;
                  padding-left: 35px;
                  @include mediaQuery($iPad) {
                    font-size: 14px;
                  }
                  @include mediaQuery($belowiPad) {
                    font-size: 17px;
                  }
                  @include mediaQuery($mobile) {
                    font-size: 13px;
                  }

                  @include mediaQuery($smallMobile) {
                    font-size: 11px;
                    font-weight: 400;
                    padding-left: 25px;
                  }
                  &:before {
                    content: "";
                    -webkit-appearance: none;
                    background-color: transparent;
                    border: 2px solid $black;
                    width: 25px;
                    height: 25px;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 0%;
                    vertical-align: middle;
                    cursor: pointer;
                    @include mediaQuery($mobileSmall) {
                      padding: 8px;
                    }
                    @include mediaQuery($smallMobile) {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
                input {
                  padding: 0;
                  height: initial;
                  width: initial;
                  margin-bottom: 0;
                  display: none;
                  cursor: pointer;
                }
                input:checked + label:after {
                  content: "";
                  display: block;
                  position: absolute;
                  top: 4px;
                  left: 9px;
                  width: 6px;
                  height: 14px;
                  border: solid $black;
                  border-width: 0 2px 2px 0;
                  transform: rotate(45deg);
                  @include mediaQuery($iPad) {
                    left: 10px;
                  }
                  @include mediaQuery($mobile) {
                    left: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sd_inner_sec_block_ {
  // border-bottom: 2px solid $black;
  padding: 51px 0 20px;
  @include mediaQuery($desktop) {
    padding: 51px 20px 20px;
  }
  @include mediaQuery($extraLarge) {
    padding: 40px 00px 10px;
  }
  @include mediaQuery($smallMobile) {
    padding: 30px 00px 10px;
  }
  @include mediaQuery($mobile) {
    padding: 30px 00px 10px;
  }
  @include mediaQuery($smallMobile) {
    padding: 30px 00px 10px;
  }
  &:last-child
  {
    border-bottom: 2px solid $black;
  }
}
.sd_filter_sec{
  margin-top: 32px;
  border-top: 2px solid black;
}
.sd_Filter_by_Interest
{

  padding: 20px 0;

  .Interest_filter_title
  {
    @include defaultFontStyleFestival($font_family_bold, 11, 14, $black);
  }
  .sd_main_category
  {
    margin-top: 16px;
    .sd_inner_category{
      margin-bottom: 14px;
      max-width: 150px;
      width: 100%;
      @include mediaQuery($mobile) {
        max-width: 114px;
      }
      &.active
      {
        span
        {
           font-family: $font_family_bold !important;
        }
      }
    }
    
    .sd_filter_icon
    {
      border: 1px solid;
      max-width: 38px;
      min-width: 38px;
      width: 100%;
      padding: 8px 5px;
      text-align: center;  
      img
      {
        width: 100%;
      }
    }
    .sd_filter_title
    {
      margin: 0 10px;
      max-width: 75px;
      width: 100%;
      @include defaultFontStyleFestival($font_family_normal, 9, 13, $black);
      @include mediaQuery($extraLarge) {
        margin: 0 10px 0 3px;
      }
    }
    
  }
}

.sd_presention_block {
  // border-bottom: 2px solid $black;
  .sd_presention_block_inner {
    display: flex;
    @include mediaQuery($extraLarge) {
      flex-wrap: wrap;
    }
    .sd_presention_border {
      border-right: 2px solid $black;
      width: 50%;
      @include mediaQuery($extraLarge) {
        border: 0;
        width: 100%;
      }
    }
    .sd_presention_block_left {
      width: 100%;
      padding: 48px 40px 10px 0;
      position: relative;
      @include mediaQuery(1700px) {
        padding: 48px 40px 10px 15px;
      }
      @include mediaQuery(1470px) {
        padding: 48px 20px 10px 20px;
      }
      @include mediaQuery(1473px) {
        padding: 48px 20px 20px;
      }
      @include mediaQuery($desktop) {
        padding: 30px 20px 20px;
      }
      @include mediaQuery($iPad) {
        border: 0px;
      }
      @include mediaQuery($extraLarge) {
        padding: 40px 20px 0;
      }
      @include mediaQuery($Largemobile) {
        border: 0;
      }
      @include mediaQuery($mobile) {
        padding: 30px 15px 0;
      }
      @include mediaQuery($smallMobile) {
        padding: 30px 10px 0;
      }
      .sd_block_left_images {
        width: 100%;
        min-height: 100px;
        max-height: 465px;
        @include mediaQuery(1700px) {
          width: 100%;
        }
        @include mediaQuery($extraLarge) {
          max-height: 500px;
        }
        @include mediaQuery($iPad) {
          max-height: 100%;
          height: auto;
        }
        img {
          width: 100%;
          max-height: 465px;
          @include mediaQuery($extraLarge) {
            max-height: 500px;
          }
          @include mediaQuery($iPad) {
            max-height: 100%;
            height: auto;
          }
        }
      }
      .sd_block_left_des {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 20px;
        @include mediaQuery($desktop) {
          width: 100%;
        }
        @include mediaQuery($iPad) {
          padding-bottom: 0;
        }
        h2 {
          @include defaultFontStyle(400, 40, 40, $black);
          letter-spacing: -0.96px;
          font-family: $font_family_normal !important;
          @include mediaQuery($desktop) {
            font-size: 35px;
          }
          @include mediaQuery($extraLarge) {
            font-size: 30px;
          }
          @include mediaQuery($mobile) {
            font-size: 25px;
            line-height: 31px;
          }
          @include mediaQuery($mobile) {
            font-size: 20px;
            line-height: 27px;
          }
        }
        span {
          @include flex_align_center;
          @include defaultFontStyle(bold, 18, 80, $black);
          letter-spacing: -0.432px;
          @include mediaQuery($mobile) {
            line-height: 4rem;
          }
          button {
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
          }
          img {
            padding-left: 10px;
          }
        }
        p {
          @include defaultFontStyle(400, 16, 23, $black);
          letter-spacing: -0.384px;
          @include mediaQuery($mobile) {
            font-size: 15px;
          }
        }
        a {
          display: flex;
          align-items: center;
          @include defaultFontStyle(500, 22, 80, $black);
          letter-spacing: -0.528px;
          @include mediaQuery($mobile) {
            line-height: 5rem;
            padding-bottom: 10px;
            font-size: 18px;
          }
          .sd_arrow {
            padding-left: 10px;
            width: 32px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    // sd_presention_block_right
    .sd_presention_block_section_right {
      // width: 50%;
      width: 100%;

      @include mediaQuery($extraLarge) {
        width: 100%;
      }
      .sd_presention_block_right {
        display: flex;
        flex-wrap: wrap ;
        // @include flex_justify_between;
        // padding: 48px 0 0 20px;
        padding-top: 48px;
        @include mediaQuery(1473px) {
          padding: 48px 5px 20px;
        }
        @include mediaQuery($desktop) {
          padding: 30px 5px 20px;
        }
        @include mediaQuery($extraLarge) {
          padding: 0px 0px 20px;
          justify-content: space-around;
          flex-wrap: wrap;
        } 
        @include mediaQuery($iPad) {
          padding: 0px 0px 0;
  
        }
       
      }
    }
  }
}

// sd_presention_block_right
.sd_presention {
  width: 50% !important;
  @include mediaQuery(1700px) {
    width: 100% !important;
  }
}

.sd_presention_left_inner {
  position: relative;
  padding-right: 30px;
  width: 25% !important;
  padding: 0 15px 0;
  cursor: pointer;
  &:first-child
  {
    padding-left: 0;
  }
  &:last-child
  {
    padding-right: 0;
  }
  @include mediaQuery($desktop) {
    padding: 0 10px 0;
  }
  @include mediaQuery($extraLarge) {
    width: 50% !important;
  }
  @include mediaQuery($iPad) {
    width: 100% !important;
    padding-left: 10px;
  }
  .sd_block_right_image {
    // min-height: 100px;
    height: 191px;
    overflow: hidden;
    @include mediaQuery($extraLarge) {
      height: 280px;
    }
    img {
      width: 100%;
      // height: 250px;
      // max-width: 340px;
      @include mediaQuery($extraLarge) {
        height: 280px;
      }
     
    }
  }
  .sd_block_right_dec {
    padding-top: 20px;
    h2 {
      @include defaultFontStyle(400, 30, 32, $black);
      @include mediaQuery($mobile) {
        font-size: 25px;
      }
      @include mediaQuery($mobile) {
        font-size: 20px;
        line-height: 27px;
      }
    }
    span {
      @include flex_align_center;
      @include defaultFontStyle(bold, 18, 60, $black);
      @include mediaQuery($Largemobile) {
        line-height: 4rem;
      }
      button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
      }
      img {
        padding-left: 10px;
      }
    }
    p {
      @include defaultFontStyle(normal, 16, 23, $black);
      @include mediaQuery($mobile) {
        font-size: 15px;
      }
    }
    a {
      display: flex;
      align-items: center;
      @include defaultFontStyle(500, 22, 80, $black);
      @include mediaQuery($mobile) {
        line-height: 5rem;
        padding-bottom: 10px;
        font-size: 18px;
      }
      .sd_arrow {
        padding-left: 10px;
        width: 32px;
        img {
          width: 100%;
        }
      }
    }
  }
}

// inner homepage sec
.sd_inner_sec_block {
  .sd_inner_sec_container {
    max-width: 1510px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    @include mediaQuery($extraLarge) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

//pagination
.customPagination {
  .disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  ul {
    display: inline-flex;
    align-items: center;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;
    li {
      button {
        background: none;
        border: none;
        outline: none;
        font-size: 16px;
        line-height: 23px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        padding: 0;
        font-family: $font_family_normal !important;
        img {
          width: 25px;
        }
      }
    }
  }
}
.active_page {
  background: white;
  border-radius: 50%;
  padding: 0 0px;
}
.sd_pagination_sec {
  padding: 38px 0 88px;
  @include mediaQuery($iPad) {
    padding: 38px 0;
  }
  @include mediaQuery($Largemobile) {
    padding: 38px 0;
  }

  .sd_pagination_container {
    padding: 0;
    max-width: 1480px;
    margin: 0 auto;
    text-align: center;
   
  }
}

// sd_featured_event_card

.sd_medium_card {
  margin-bottom: 71px !important;

  @include mediaQuery($desktop) {
    margin-bottom: 50px !important;
  }
  @include mediaQuery($extraLarge) {
    margin-bottom: 23px !important;
  }
  @include mediaQuery($mobile) {
    margin-bottom: 0 !important;
  }
}
.sd_medium_card_home {
  padding-top: 32px;
  overflow: hidden;
  padding: 32px 0px 0;
  border-bottom: 2px solid $black;

  .sd_container {
    margin-left: 5vw;

    @include mediaQuery(1680px) {
      max-width: 1580px;
    }
    @include mediaQuery($desktop) {
      margin-left: 15px;
    }
    @include mediaQuery($iPad) {
      margin-left: 0;
    }
    @include mediaQuery($smallMobile) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .sd_mediumcard_view_wpr {
    position: relative;
    width: 100%;
    .sd_mediumcard_view_date {
      margin-bottom: 10px;
      h3 {
        @include defaultFontStyle(400, 60, 40, $black);
        letter-spacing: -1.44px;
        @include mediaQuery($extraLarge) {
          @include defaultFontStyle(400, 50, 40, $black);
        }
        @include mediaQuery($iPad) {
          @include defaultFontStyle(400, 30, 40, $black);
        }
        @include mediaQuery($mobile) {
          @include defaultFontStyle(400, 22, 36, $black);
        }
        @include mediaQuery($smallMobile) {
          @include defaultFontStyle(400, 20, 22, $black);
        }
      }
      p {
        text-transform: uppercase;
        line-height: -1px;
        @include defaultFontStyle(bold, 18, 40, $black);
        letter-spacing: -0.432px;
        @include mediaQuery($mobileSmall) {
          font-size: 15px;
          line-height: 8rem;
        }
      }
      .sd_home_slick_buttons {
        button {
          background: none;
          cursor: pointer;
          border: none;
          outline: none;
          padding: 0;
          margin-right: 29px;
          @include mediaQuery($iPad) {
            width: 20px;
            margin-right: 15px;
          }
        }
      }
      .sd_medium_card_home_buttons {
        margin: -41px 0 0 32px;
        @include mediaQuery($mobileSmall) {
          margin: 0 0 0 62px;
        }
        @include mediaQuery($mobileSmall) {
          margin: 0 0 0 25px;
        }
      }
      .arrow_button_feature {
        margin: 0px 0 0 32px !important;
      }
    }
    .sd_mediumcard_view_slider {
      .slick-track {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100% !important;
      }
      .slick-slide {
        margin: 0 34px 0 0;
        width: 467px !important;
        @include mediaQuery($iPad) {
          width: 100% !important;
          margin: 0 0px 0 0;
        }
        @include mediaQuery($Largemobile) {
          width: 100% !important;
          margin: 0;
        }
      }
      .slick-list {
        height: 100% !important;
        @include mediaQuery($iPad) {
          padding: 0;
          margin-bottom: 20px;
        }
      }
      .sd_mediumcard_view {
        @include mediaQuery($iPad) {
          margin-bottom: 0;
        }
      }
      .sd_largecard_view {
        // for medium card view
        .sd_card_image {
          // height: 810px;
          img {
            width: 100%;
            height: 100%;
            margin: auto;
            object-fit: cover;
            @include mediaQuery($iPad) {
              height: auto;
            }
          }
        }
        &.sd_mediumcard_view {
          width: calc(50% - 21px);
          @include mediaQuery($belowiPad) {
            width: 100%;
          }
          .sd_card_image {
            width: 467px;
            @include mediaQuery($iPad) {
              width: 100%;
            }
            img {
              height: 310px;
              width: 467px;
              @include mediaQuery($iPad) {
                width: 100%;
                height: 450px;
              }
              @include mediaQuery($mobile) {
                width: 100%;
                height: auto;
              }
            }
          }
          .sd_card_content {
            padding-top: 5px;
            .sd_card_left {
              max-width: 100%;
            }
          }
        }

        .sd_card_content {
          padding-top: 20px;
          .sd_card_left {
            max-width: 1010px;
            @include mediaQuery($desktop) {
              max-width: 70%;
            }
            @include mediaQuery($large) {
              max-width: 100%;
            }
            h2 {
              text-transform: uppercase;
              @include defaultFontStyle(400, 30, 32, $black);
              letter-spacing: -0.72px;
              @include mediaQuery($mobileSmall) {
                font-size: 22px;
                line-height: 25px;
              }
            }
            p {
              @include defaultFontStyle(400, 16, 23, $black);
              letter-spacing: -0.384px;
            }
            span {
              @include flex_align_center;
              @include defaultFontStyle(bold, 18, 80, $black);
              letter-spacing: -0.432px;
              @include mediaQuery($iPad) {
                line-height: 5rem;
              }
              img {
                width: 19px;
                margin: 0;
                margin-left: 10px;
              }
            }
            .sd_btn_black {
              border: none;
              padding: 0;
              @include flex_align_center;
              @include defaultFontStyle(500, 22, 80, $black);
              letter-spacing: -0.528px;
              .sd_arrow {
                padding-left: 10px;
                width: 32px;
                img {
                  width: 100%;
                }
              }
              @include mediaQuery($iPad) {
                line-height: 5rem;
              }
              @include mediaQuery($smallMobile) {
                padding: 0 !important;
              }
              @include mediaQuery($mobile) {
                padding: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

span {
  background: transparent !important;
  font-family: $font_family_normal !important;
}
//paragraph_span
.paragraph_span_22 {
  p {
    line-height: 32px !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    font-family: $font_family_normal !important;
    color: #000 !important;
    background: transparent !important;
    @include mediaQuery($Largemobile) {
      line-height: 30px !important;
      font-size: 20px !important;
    }
    @include mediaQuery($mobile) {
      line-height: 29px !important;
      font-size: 18px !important;
    }
    @include mediaQuery($smallMobile) {
      line-height: 28px !important;
      font-size: 16px !important;
    }
    span {
      line-height: 32px !important;
      font-size: 22px !important;
      font-weight: 400 !important;
      font-family: $font_family_normal !important;
      color: #000 !important;
      background: transparent !important;
      @include mediaQuery($Largemobile) {
        line-height: 30px !important;
        font-size: 20px !important;
      }
      @include mediaQuery($mobile) {
        line-height: 29px !important;
        font-size: 18px !important;
      }
      @include mediaQuery($smallMobile) {
        line-height: 28px !important;
        font-size: 16px !important;
      }
    }
  }

  a {
    color: $purple !important;
    border: none !important;
    line-height: 32px !important;
    min-width: unset !important;
    font-size: 22px !important;
    padding: 0 !important;
    &:hover {
      background: none !important;
      color: $purple !important ;
    }
    span {
      font-size: 22px !important;
      background: transparent !important;
      line-height: 32px !important;
      font-family: $font_family_normal !important;
      font-weight: 400 !important;
      color: $purple !important;
      @include mediaQuery($Largemobile) {
        line-height: 30px !important;
        font-size: 20px !important;
      }
      @include mediaQuery($mobile) {
        line-height: 29px !important;
        font-size: 18px !important;
      }
      @include mediaQuery($smallMobile) {
        line-height: 28px !important;
        font-size: 16px !important;
      }
    }
  }

  ol {
    padding: 0 0 0 30px;
    li {
      line-height: 32px !important;
      font-size: 22px !important;
      font-weight: 400 !important;
      font-family: $font_family_normal !important;
      color: #000 !important;
      background: transparent !important;
      @include mediaQuery($Largemobile) {
        line-height: 30px !important;
        font-size: 20px !important;
      }
      @include mediaQuery($mobile) {
        line-height: 29px !important;
        font-size: 18px !important;
      }
      @include mediaQuery($smallMobile) {
        line-height: 28px !important;
        font-size: 16px !important;
      }
      span {
        line-height: 32px !important;
        font-size: 22px !important;
        font-weight: 400 !important;
        font-family: $font_family_normal !important;
        color: #000 !important;
        background: transparent !important;
        @include mediaQuery($Largemobile) {
          line-height: 30px !important;
          font-size: 20px !important;
        }
        @include mediaQuery($mobile) {
          line-height: 29px !important;
          font-size: 18px !important;
        }
        @include mediaQuery($smallMobile) {
          line-height: 28px !important;
          font-size: 16px !important;
        }
      }
    }
  }
  ul {
    padding: 0 0 0 20px;
    li {
      line-height: 32px !important;
      font-size: 22px !important;
      font-weight: 400 !important;
      font-family: $font_family_normal !important;
      color: #000 !important;
      background: transparent !important;
      @include mediaQuery($Largemobile) {
        line-height: 30px !important;
        font-size: 20px !important;
      }
      @include mediaQuery($mobile) {
        line-height: 29px !important;
        font-size: 16px !important;
      }
      @include mediaQuery($smallMobile) {
        line-height: 28px !important;
        font-size: 16px !important;
      }
      span {
        line-height: 32px !important;
        font-size: 22px !important;
        font-weight: 400 !important;
        font-family: $font_family_normal !important;
        color: #000 !important;
        background: transparent !important;
        @include mediaQuery($Largemobile) {
          line-height: 30px !important;
          font-size: 20px !important;
        }
        @include mediaQuery($mobile) {
          line-height: 29px !important;
          font-size: 16px !important;
        }
        @include mediaQuery($smallMobile) {
          line-height: 28px !important;
          font-size: 16px !important;
        }
      }
    }
  }
}

.paragraph_span_16 {
  p {
    line-height: 23px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: $font_family_normal !important;
    color: #000 !important;
    background: transparent !important;
    letter-spacing: -0.384px;
    @include mediaQuery($mobile) {
      font-size: 15px !important;
    }
    span {
      line-height: 23px !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      font-family: $font_family_normal !important;
      color: #000 !important;
      letter-spacing: -0.384px;
      background: transparent !important;
      @include mediaQuery($mobile) {
        font-size: 15px !important;
      }
    }
  }
  a {
    padding: 0 !important;
    color: $purple !important;
    border: none !important;
    min-width: unset !important;
    line-height: 23px !important;
    font-size: 1.6rem !important;
    &:hover {
      background: none !important;
      color: $purple !important ;
    }
    span {
      line-height: 23px !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      font-family: $font_family_normal !important;
      color: #000 !important;
      background: transparent !important;
      @include mediaQuery($mobile) {
        font-size: 15px !important;
      }
    }
  }
  ol {
    padding: 0 0 0 30px;
    li {
      line-height: 23px !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      font-family: $font_family_normal !important;
      color: #000 !important;
      background: transparent !important;
      @include mediaQuery($mobile) {
        font-size: 15px !important;
      }
      span {
        line-height: 23px !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        font-family: $font_family_normal !important;
        color: #000 !important;
        background: transparent !important;
        @include mediaQuery($mobile) {
          font-size: 15px !important;
        }
      }
    }
  }
  ul {
    padding: 0 0 0 20px;
    li {
      line-height: 23px !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      font-family: $font_family_normal !important;
      color: #000 !important;
      background: transparent !important;
      @include mediaQuery($mobile) {
        font-size: 15px !important;
      }
      span {
        line-height: 23px !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        font-family: $font_family_normal !important;
        color: #000 !important;
        background: transparent !important;
        @include mediaQuery($mobile) {
          font-size: 15px !important;
        }
      }
    }
  }
}

.paragraph_span_14 {
  @include fontStyle(300, 14, 21, $black, -0.336px);
  p {
    font-family: $font_family_normal !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 1.4rem !important;
    line-height: 2.1rem !important;
    color: #000 !important;
    letter-spacing: -0.336px !important;
    span {
      font-family: $font_family_normal !important;
      font-style: normal !important;
      font-weight: 300 !important;
      font-size: 1.4rem !important;
      line-height: 2.1rem !important;
      color: #000 !important;
      letter-spacing: -0.336px !important;
    }
  }
  a {
    color: $purple !important;
    border: none !important;
    padding: 0 !important;
    line-height: 23px !important;
    font-size: 1.4rem !important;
    min-width: unset !important;
    &:hover {
      background: none !important;
      color: $purple !important ;
    }
    span {
      line-height: 23px !important;
      font-size: 1.4rem !important;
      font-weight: 400 !important;
      font-family: $font_family_normal !important;
      color: #000 !important;
      background: transparent !important;
      @include mediaQuery($mobile) {
        font-size: 15px !important;
      }
    }
  }
  ol {
    padding: 0 0 0 20px;
    li {
      font-family: $font_family_normal !important;
      font-style: normal !important;
      font-weight: 300 !important;
      font-size: 1.4rem !important;
      line-height: 2.1rem !important;
      color: #000 !important;
      letter-spacing: -0.336px !important;
      span {
        font-family: $font_family_normal !important;
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 1.4rem !important;
        line-height: 2.1rem !important;
        color: #000 !important;
        letter-spacing: -0.336px !important;
      }
    }
  }
  ul {
    padding: 0 0 0 20px;
    li {
      font-family: $font_family_normal !important;
      font-style: normal !important;
      font-weight: 300 !important;
      font-size: 1.4rem !important;
      line-height: 2.1rem !important;
      color: #000 !important;
      letter-spacing: -0.336px !important;
      span {
        font-family: $font_family_normal !important;
        font-style: normal !important;
        font-weight: 300 !important;
        font-size: 1.4rem !important;
        line-height: 2.1rem !important;
        color: #000 !important;
        letter-spacing: -0.336px !important;
      }
    }
  }
}

// slider home page
.sd_home_slider {
  padding: 32px 0;
  @include mediaQuery($desktop) {
    padding: 32px 20px;
  }
  @include mediaQuery($Largemobile) {
    padding: 32px 15px;
  }
  @include mediaQuery($mobile) {
    padding: 18px 00px;
  }
  @include mediaQuery($smallMobile) {
    padding: 18px 00px;
  }
  .sd_container_slider {
    max-width: 1510px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    @include mediaQuery(1700px) {
      width: 100%;
    }
  }
}
#sd_container {
  .sd_slider_title {
    padding-bottom: 20px;
  }
}
.sd_slider_title {
  display: flex;
  align-items: center;
  h2 {
    @include fontStyle(400, 60, 40, $black, -1.44px);
    padding: 10px 20px;
    @include mediaQuery($extraLarge) {
      font-size: 40px;
      padding: 10px 9px;
    }
    @include mediaQuery($iPad) {
      padding: 10px 5px 19px 0;
    }
    @include mediaQuery($Largemobile) {
      font-size: 35px;
      padding: 10px 0px;
    }
    @include mediaQuery($mobile) {
      font-size: 29px;
      padding: 10px 5px 10px 0;
    }
    @include mediaQuery($mobileSmall) {
      font-size: 26px;
      padding: 10px 5px 0px 0;
    }
    @include mediaQuery($smallMobile) {
      font-size: 22px;
      padding: 10px 5px 0px 0;
    }
  }
  .sd_slider_arrows {
    @include mediaQuery($mobile) {
      left: 10px;
    }
    @include mediaQuery($mobileSmall) {
      padding-top: 10px;
    }
    button {
      background: transparent;
      border: none;
      padding: 0 15px;
      outline: none;
      cursor: pointer;
      @include mediaQuery($mobile) {
        padding: 0 5px;
        width: 41%;
      }
    }
  }
}
.slider-images {
  padding: 0 15px;
  @include mediaQuery($extraLarge) {
    padding: 0 10px;
  }
  @include mediaQuery($iPad) {
    padding: 0;
  }
  .sd_slider_img {
    height: 310px;
    width: 100%;
    @include mediaQuery($extraLarge) {
      height: 245px;
    }
    @include mediaQuery($iPad) {
      height: 380px;
    }
    @include mediaQuery($mobile) {
      height: 265px;
    }
    img {
      height: 310px;
      width: 100%;
      @include mediaQuery($extraLarge) {
        height: 245px;
      }
      @include mediaQuery($iPad) {
        height: 380px;
      }
      @include mediaQuery($mobile) {
        height: 265px;
      }
    }
  }
  .content_slider {
    padding-top: 14px;
    .artist_heading {
      padding-top: 14px;
    }
    .sd_time_span {
      @include flex_align_center;
      @include defaultFontStyle(bold, 18, 80, $black);
      letter-spacing: -0.432px;
      button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
      }
      @include mediaQuery($iPad) {
        line-height: 5rem;
      }
      img {
        width: 19px;
        margin: 0;
        margin-left: 10px;
      }
    }
    p {
      @include defaultFontStyle(400, 16, 23, $black);
      letter-spacing: -0.384px;
    }
    span {
      text-transform: uppercase;
      @include defaultFontStyleFestival($font_family_bold, 18, 40, $black, -0.432px);
      font-family: $font_family_bold !important;
      @include mediaQuery($mobile) {
        line-height: 6rem;
      }
    }
    h2 {
      text-transform: uppercase;
      @include fontStyle(400, 30, 32, $black, -0.72px);
    }
    a {
      text-transform: capitalize;
      @include fontStyle(500, 22, 80, $black, -0.528px);
      display: flex;
      align-items: center;
      .sd_arrow {
        width: 6%;
        // margin-top: 5px;
        @include mediaQuery($iPad) {
          width: 5%;
        }
        @include mediaQuery($Largemobile) {
          width: 6%;
        }
        @include mediaQuery($mobile) {
          width: 10%;
        }
        img {
          width: 100%;
          padding-left: 10px;
        }
      }
    }
  }
}

.sd_block-title {
  padding-bottom: 15px;
}
.sd_block-titles {
  padding-bottom: 0;
}

//sd_eventdate_space
.sd_eventdate_space {
  padding-top: 20px;
}
.sd_eventdate {
  padding-top: 0;
}
.sd_offer_space {
  padding-bottom: 20px;
  text-transform: uppercase;
}

.sd_offer {
  padding-bottom: 0;
  text-transform: uppercase;
}

.home-right-btn {
  width: 31px;
  height: 21px;
}

.srv-validation-message {
  color: red;
}
.sd_input_success_msg {
  color: red;
}
.active {
  background-color: white;
  border-radius: 50%;
}

.Available_Now {
color:  #FF625A !important;
}

.pagination{ display: flex; justify-content: center; align-items: center;}
.pagination li{ list-style: none; }
.pagination li a{background: none; display: block;border: none;outline: none;font-size: 16px;line-height: 30px;width: 30px;height: 30px;cursor: pointer;padding: 0;text-align: center;font-family: $font_family_normal !important;}
.pagination li.previous a{ background: url("/village/images/arrow_left.svg") no-repeat center center; background-size: contain; margin-right: 10px; }
.pagination li.next a{ background: url("/village/images/arrow_right.svg") no-repeat center center; background-size: contain;margin-left: 10px;}
.pagination li a img{display: none;}
.pagination li.previous.disabled , .pagination li.next.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}


