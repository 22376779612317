// Pre-Loader
.sd_loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include square(100%);
    @include flex_center_center; 
    background: rgba(0, 0, 0, 0.15);
    z-index: 998;
    svg{    
        margin: auto;
        position: relative;
        z-index: 999;
    }
    img{
        width: 200px;
        height: 200px;
        object-fit: cover;
    }
}