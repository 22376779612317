// Filter
.filter_overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0);
    z-index: 2;
}
// Button


.react-datepicker__day--outside-month {
    color: rgba(0, 0, 0, 0.473) !important;
    pointer-events: none;
  }

.sd_filter_btn_wpr{
    margin-right: 67px;
    display: none;
    @include mediaQuery($extraLarge) {
        margin-right: 20px;
    }
    @include mediaQuery($ipad) {
        order: 3;
        margin-right: 0;
    } 
    @include mediaQuery(576px) {
        @include flex_center_between;
        width: 100%;
    }
    .sd_filter_btn{
        @include flex_align_center;
        position: relative;
        padding-right: 25px;
        margin-top: 15px;
        &.active{
            .filter_arrow_up{
                display: block;
            }
            // .filter_plus_icon{
            //     // display: none;
            // }
        }
        img{
            position: absolute;
            right: 2px;
            max-width: 12px;
        }
        .filter_arrow_up{
            display: none;
        }
    }
}

// Close Filter Button
.sd_close_filter{
    position: absolute;
    // top: 25px;
    right: 25px;
    @include square(29px);
    @include buttonReset;
    @include flex_justify_center;
    display: none;

    @include mediaQuery(576px){
        @include flex;
        margin-top: -25px;
       
    }
    span{
        display: block;
        width: 2px;
        background: $black !important;
        height: 37px;
        &:first-child{
            transform: rotate(-45deg) translate(4px, -3px);
        }
        &:last-child{
            transform: rotate(45deg) translate(-4px, -3px);
        }
        @include mediaQuery(576px){
            height: 25px;
        }
    }
}

// Filter List
.sd_filters_wpr {
    // display: none;
	@include transitionStyle(0.3, linear);
    background: $white; 
    // padding-top: 52px;

    &.sd_filters_active{
        display: block;
    }

    @include mediaQuery(576px){
        // position: fixed;
        display: none;
        z-index: 99;
        @include square(100%);
        top: 0;
        left: 0;
        overflow: auto;
        .sd_inner_container{
            padding: 0 16px 0 19px;
        }
        &.sd_filters_active{
            position: fixed;
        }
    
    }
.filter_head{
    display: flex;
    padding: 24px 18px 0;
    display: none;
    @media only screen and (max-width: 577px){
        padding: 20px 18px;
        justify-content:space-between;
        display: flex;
}
.sd_close_filter {
    @include mediaQuery($ipad){
        right: 14px;
    }
}

}
.sd_filters_reset_btn{
    padding: unset;
    display: unset;
}
    // Header
    .sd_filters_options_header{
        @include flex_align_center;
        position: relative;
        z-index: 5;
        padding: 11px;
        &.sd_active{
           padding-left: 14px;
           border: transparent;
           box-shadow:0 -2px 4px 0px  rgba(0, 0, 0, 0.5);
           @include mediaQuery(576px){
               box-shadow: none;
           }
           h4{
            border: transparent;
           }
           .sd_filter_click{
                transform:rotate(0deg) translateY(-50%)
           }
        }
        h4{
            @include defaultFontStyleFestival($font_family_normal, 14, 18, $black);
            font-weight: 400;
            letter-spacing: -0.53px;
            @include border_bottom_black;
            width: 100%;
            cursor: pointer;
            @include mediaQuery(576px){
                @include mediaFontStyle(16, 20);
                letter-spacing: -0.42px;
                cursor: pointer;
            }
        }
      
        button{
            @include buttonReset;
            margin-left: 6px;
            @include mediaQuery(576px){
                margin-left: 4px;
            }
            img{
                width: 12px;
                @include mediaQuery(576px){
                    width: 14px;
                }
            }
        }
        .sd_question_button{
            @include mediaQuery($ipad){
                .sd_question_tooltip{
                    top: -80px;
                    left: 50%;
                    transform: translateX(-50%);
                    &:before{
                        top: auto;
                        left: 50%;
                        transform: translateX(-50%) rotate(-45deg);
                        bottom: -7px;
                    }
                }
            }
        }
        .sd_filter_click{
            cursor: pointer;
            max-width: 12px;
            transition: 0.3s ease-in-out;
            // display: none;
            // @include mediaQuery(576px){
                display: block;
                position: absolute;
                right: 11px;
                top: 50%;
                transform: rotate(180deg) translateY(50%);
            // }
        }
    }

    .sd_filters_sec{
        @include flex;
        margin-left: -10px;
        position: relative;
        @include mediaQuery($desktop){
            @include flex_dir_column_rev;
            margin-left: 0;
        }
        @include mediaQuery(576px){
            .sd_filters_options_wpr{
                order: 1;
            }
            .sd_date_filter{
                margin-top: 30px;
            }
        }
    }
    
    // Tooltip
    .tooltip-filter-access{
        .__react_component_tooltip{
            width: 190px;
            &.place-top{
                margin-top: 20px;
            }
        }
    }
    .tooltip-filter-interest{
        .__react_component_tooltip{
            width: 290px;
            &.place-top{
                margin-top: 20px;
            }
        }
    }
    .tooltip-filter-accessibility{
        padding-right: 10px !important;
        .__react_component_tooltip{
            width: 290px;
            &.place-right {
                margin-left: 0;
            }
            &.place-bottom {
                margin-top: 28px;
            }
        }
    }
}

// Reset Filters Button
.sd_filters_reset_btn{
    padding: 17px 0 3px;
    @include flex;
    align-items: flex-start;
    &.sd_filters_reset_btn_popup{
        display: none;
        @include mediaQuery(576px){
            display: block;
            padding: 25px 10px 3px;
        }
    }
    button{
        @include buttonReset;
        @include defaultFontStyleFestival($font_family_normal, 14, 23, $lygreen);
        color: $lygreen !important;
        letter-spacing: -0.34px;
        // font-family: "La Nord V2.1 Semi Italic";
        font-style: italic !important;
        font-size: 14px !important;
        margin-right: 15px;
        flex-shrink: 0;
        position: relative;
        z-index: 4;
        @include mediaQuery(576px){
            margin-right: 0;
        }
        @include mediaQuery($mobile){
            font-size: 10px;
        }
    }
  
}

// Options
.sd_filters_options_block {
    position: relative;
}
.sd_date_filter{
    flex-shrink: 0;
    position: absolute;
    right: 0;
    top: -25px;
    width: 435px;
    @include mediaQuery($desktop){
        position: static;
        width: 100%;
        margin-bottom: 10px;
    }
}
.sd_filters_options_wpr{
    max-width: calc(100% - 430px);
    width: 100%;
    padding-top: 11px;
    @include mediaQuery($desktop){
        max-width:100%;
    }
    @include mediaQuery($ipad){
        @include f_wrap;
    }
    .mobile_hide{
        @include mediaQuery(576px){
            display: none
        }
    }
    @include mediaQuery(576px){
        @include flex_dir_column;
         margin-top: 0;
      
    }
    
    .sd_filters_options_sec{
        max-width: 160px;
        width: 100%;
        margin-right: 10px;
        @include mediaQuery($large){
            @include f_wrap;
            // max-width: calc(50% - 40px) !important;
            // margin-right: 40px !important;
        }
        @include mediaQuery(576px){
            max-width: 100% !important;
        }
        &:empty{
            display: none;
        }
        &:last-child{
            margin-right: 0; 
            @include mediaQuery(576px){
                margin-right: 10px;
            }           
        }
        // &.sd_filters_options_sec_two{
        //     .sd_filters_options_block{
        //         &:first-child{
        //             margin-bottom: 25px;
        //         }
        //     }

        //     @include mediaQuery($large){
        //         @include flex_justify_between;
        //         max-width: 100% !important;
        //         margin-right: 10px;
        //         .sd_filters_options_block{
        //             width: 100%;
        //         }
        //     }

        //     @include mediaQuery(576px){
        //         @include flex_dir_column;
        //         .sd_filters_options_block{
        //             max-width: 100%;
        //         }
        //     }
        // }
        .sd_filters_options_block{
            @include mediaQuery($large){
                margin-bottom: 10px !important;
            }
        }
    }
    .sd_filters_options{
        max-height: 0;
        transition: height 0.3s;
        overflow: hidden;
        min-width: 260px;
        max-width: 260px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 100%;
        z-index: 6;
        margin-top: -5px;
        background: #fff;
        @include mediaQuery(576px){
            box-shadow: none;
        }
        @include mediaQuery(567px){
            position: static;
            display: none;
            max-width: 100%;
            width: 100%;
        }
        &.sd_active{
            display: block;
            max-height: 350px;
            overflow-y: auto;
            padding: 6px 12px 12px;
        }
        .sd_filter_checkbox{
            input{
                display: none;
                &:checked {
                    + label {
                        @include defaultFontStyleFestival($font_family_bold, 12, 16, $black);
                        @include mediaQuery(576px){
                            font-size: 14px;
                        }
                        &:after{
                            opacity: 0;
                        }
                    }
                }
            }
            label{
                font-family: $font_family_normal;
                font-size: 12px;
            }
        }
    }
}
.sd_filter_category_section{
    .sd_inner_container{
        position: relative;
        padding: 0 !important;
    }
}
// Filter by day and time
.sd_filters_day{
    max-width: 422px;
    width: 100%;
    @include mediaQuery($desktop){
        // @include flex_align_center;
        align-items: center;
        margin-left: auto;
    }
    
    @include mediaQuery($large){
        display: block;
    } 
    @include mediaQuery(576px){
        padding: 0 11px;
        margin-left: unset;
    }
    .date_filter_title{
        @include defaultFontStyleFestival($font_family_bold, 11, 20, $black);
    }
    .sd_filters_options_header{
        border: 0;
        position: relative;
        h4{
            @include defaultFontStyle($font_family_bold, 11, 20, $black);
            
        } 
       
    }
    .sd_filters_day_sec{
        // margin-right: 40px;
        // @include mediaQuery($desktop){
        //     max-width: calc(50% - 40px);
        //     margin-right: 40px;
        //     width: 100%;
        // }      
        @include mediaQuery($large){
            max-width: 100%;
        }      
        
    }

    .sd_filters_day_wpr{
        @include flex_align_center;
        h4{
            @include defaultFontStyle($font_family_normal, 22, 23, $black);
            letter-spacing: -0.53px;
            text-transform: uppercase;
            margin: 0 26px 0 22px;
            @include mediaQuery($desktop){
                font-size: 20px;   
            }
            @include mediaQuery($ipad){
                font-size: 18px;   
            }
            @include mediaQuery(576px){
                @include mediaFontStyle(17.6, 18.4);
                letter-spacing: -0.42px;
                cursor: pointer;
                margin: 0 16px 0 14px;
            }
        }
        .sd_to_devide{
            margin: 0 7px;
            @include defaultFontStyle(700, 9, 10, $black);
            text-transform: uppercase;
            @include mediaQuery(576px){
                margin: 0 3px;              
            }
            
        }
        .sd_filters_date_from,.sd_filters_date_to,.sd_filter_by_time{
            border: 1px solid;
            margin-right: -1px;
        }
        .sd_filters_date_picker{
            max-width: 142px;
            width: 100%;
            position: relative;
            @include mediaQuery(576px){
                max-width: 105px;                
            }
           
            &.sd_filters_date_from{
                input{
                    padding-left: 25px;
                    @include mediaQuery(576px){
                        padding-left: 10px;                
                    }
                }
            }
            &.sd_filters_date_to{
                max-width: 120px;
                @include mediaQuery(576px){
                    max-width: 103px;
                }
            }
            .sd_cal_icon{
                position: absolute;
                left: 6px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
                @include mediaQuery(576px){
                    display: none;
                }
            }
            input{
                width: 100%;
                border: none;
                outline: none;
                @include defaultFontStyleFestival($font_family_normal, 12, 14, $black);
               // font-family: "La Nord V2.1 Semi";
                padding: 9px 10px;
                background: transparent;
                cursor: pointer;
                &::placeholder{
                    color: $black;
                }

                @include mediaQuery(576px){
                    @include mediaFontStyle(10, 13);                    
                    letter-spacing: -0.35px;
                    padding: 7px 5px;
                }
            }
            
            // Date Picker
            @include mediaQuery($mobile){
                &.sd_filters_date_to {
                    .react-datepicker-popper{
                        left: -60px !important;
                    }
                }          
            }
        }
    }
    .sd_filter_by_time{
        
        .sd_filters_date_picker{
            position: relative;
            // &:after{
            //     @include content;
            //     top: 18px;
            //     right: 18px;
            //     background: url('/program/images/arrow_up_light.svg') no-repeat;
            //     width: 17px;
            //     height: 9px;
            //     background-size: contain;
            //     transform: rotate(180deg);
            //     z-index: 0;
            //     @include mediaQuery(576px){
            //         width: 14px;
            //         height: 7px;
            //         top: 16px;
            //         right: 14px;
            //     }
            // }
        }
        .react-datepicker__input-container{
            input{
                @include mediaQuery(576px){
                    text-align: center;
                }
            }
           }
    }
}
.sd_date_arrow{
    top: 50%;
    position: absolute;
    width: 28px;
    right: 10px;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    img{
        max-width: 12px;
        cursor: pointer;
    }
    .left_arrow{
        &.disable{
            pointer-events: none;
            opacity: 0.5;
        }
    }
    .right_arrow{
        &.disable{
            pointer-events: none;
            opacity: 0.5;
        }
    }
    @include mediaQuery(576px){
        width: 20px;
        right: 7px;
    }
}
.sd_no_filters_found{
    max-width: 974px;
    width: 100%;
    margin: 0 auto 100px;
    text-align: center;
    h3{
        @include defaultFontStyleFestival($font_family_normal, 22, 35, $black, -0.53px);
        @include mediaQuery($ipad){
            @include mediaFontStyle(20, 30);
        }
        @include mediaQuery($mobile){
            @include mediaFontStyle(18, 26);
        }
    }
}

// Film page filters
.film{
    .sd_filters_options_sec{
        &:first-child{
            max-width: 315px;
            margin-right: 46px;
        }
        &:nth-child(2){
            max-width: 218px;
            margin-right: 61px;
        }
        &:nth-child(3){
            max-width: 282px;
        }
    }
}
.lg_flex_wrap{
    @include mediaQuery($desktop){
         @include f_wrap;
    }
 }