.sd_PanelView {
  background: url("/village/images/simple_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;
  @include mediaQuery($iPad) {
    background: url("/village/images/simple_mobile_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 60px;
  }
  @include mediaQuery($mobile) {
    margin-top: 7px;

  }
  
}
.sd_Event_Details_panelview {
  padding: 58px 0 65px !important;
  @include mediaQuery($extraLarge) {
    padding: 45px 0 65px !important;
  }
  @include mediaQuery($iPad) {
    padding: 30px 0 45px !important;
  }

}
.sd_main_panelview
{
  padding: 90px 0 60px 0;
  justify-content: space-between;
  @include mediaQuery($mobile) {
    padding: 0px 0 0px 0;
  }
}
.sd_PanelView_header {
  // padding: 52px 0 239px;
  max-width: 603px;
  width: 100%;
  @include mediaQuery($iPad) {
    padding-top: 45px;
  }
  @include mediaQuery($Largemobile) {
    padding-bottom: 45px;
  }
  h1 {
    width: 600px;
    @include fontStyle(400, 60, 60, $black, -1.44px);
    padding-bottom: 10px;
    @include mediaQuery($extraLarge) {
      font-size: 50px;
      line-height: 4rem;
    }
    @include mediaQuery($iPad) {
      font-size: 40px;
      width: 100%;
      padding-bottom: 10px;
    }
    @include mediaQuery($Largemobile) {
      font-size: 33px;
      padding-bottom: 0;
    }
    @include mediaQuery($mobile) {
      font-size: 30px;
    }
    @include mediaQuery($smallMobile) {
      font-size: 25px;
      line-height: 3rem;
    }
  }
  p {
    @include defaultFontStyleFestival($font_family_medium, 16, 23, $black, -0.24px);
  }

  .sd_PanelView_inner {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    @include mediaQuery($iPad) {
      margin-bottom: 20px;
    }
    @include mediaQuery($Largemobile) {
      margin-bottom: 5px;
    }
    .sd_PanelView_img {
      width: 103px;
      img {
        width: 100%;
      }
    }
    p {
      padding-right: 10px;
      padding-bottom: 0 !important;
      @include fontStyle(400, 10, 23, $black, -0.24px);
    }
  }
  .sd_Schedule {
    display: flex;
    padding-bottom: 15px;
    @include mediaQuery($iPad) {
      padding: 0;
    }
    @include mediaQuery($mobile) {
      flex-wrap: wrap;
    }
    // .sd_display_favorite{
    //   @include mediaQuery($mobileSmall) {
    //     display: none;
    //   }
    // }
    // .sd_mobile_favorite
    // {
    //   display: none;
    //   @include mediaQuery($mobileSmall) {
    //     display: flex;
    //   }
    // }
    h3 {
      @include fontStyle(500, 22, 23, $black, -0.528px);
      span {
        padding-left: 15px;
        @include mediaQuery($mobile) {
          padding-left: 10px;
        }
        @include mediaQuery($mobileSmall) {
          padding-left: 5px;
        }
      }
      @include mediaQuery($Largemobile) {
        font-size: 18px;
      }
    }
    button {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      max-width: 200px;
      width: 100%;
      @include mediaQuery($mobileSmall) {
        flex-wrap: wrap;
      }
      img {
        padding: 0 6px 0 20px;
        @include mediaQuery($mobile) {
          padding: 0 6px 0 10px;
        }
        @include mediaQuery($mobileSmall) {
          padding: 0px 5px 0 0;
        }
      }
      @include fontStyle(500, 10, 10, $white, -0.24px);
      @include mediaQuery($mobile) {
        font-size: 10px;
        padding-bottom: 10px;
      }
      @include mediaQuery($smallMobile) {
        font-size: 9px;
      }
    }
  }
  .sd_PanelView_section {
    // display: flex;
    align-items: center;
    @include mediaQuery($iPad) {
      flex-wrap: wrap;
      align-items: normal;
    }
    p {
      width: 640px;
      margin-right: 100px;
      @include fontStyle(400, 22, 27, $black, -0.528px);
      @include mediaQuery($iPad) {
        width: 100%;
        margin: 0;
        padding-right: 20px;
      }
      @include mediaQuery($Largemobile) {
        font-size: 18px;
        padding-right: 0;
      }
      @include mediaQuery($mobile) {
        font-size: 16px;
      }
      @include mediaQuery($mobileSmall) {
        font-size: 15px;
      }
      @include mediaQuery($smallMobile) {
        padding-top: 0;
      }
    }
    .sd_main_category
    {
      margin-top: 0;
      .active
      {
        background-color: unset;
      }
    }
    a {
      cursor: pointer;
      @include fontStyle(500, 22, 80, $black, -0.528px);
      border: 2px solid;
      padding: 0 20px;
      display: inline-block;
      min-width: 230px;
      text-align: center;

      justify-content: center;
      &:hover {
        background-color: $black;
        color: white;
        border-color: $black;
      }
      @include mediaQuery($extraLarge) {
        line-height: 70px;
      }
      @include mediaQuery($Largemobile) {
        font-size: 22px;
        line-height: 60px;
      }
      @include mediaQuery($mobile) {
        font-size: 18px;
        line-height: 60px;
        min-width: 137px;
      }
      @include mediaQuery($smallMobile) {
        line-height: 50px;
      }
    }
  }
}

// video
.sd_panel_view_padd {
  margin: 0px auto 0;
}
.sd_video_details {
  max-width: 777px;
  width: 100%;
  max-height: 433px;
  // margin: 0 auto;
  padding: 0 15px;
  // margin: -239px auto 0;
  position: relative;
  z-index: 85;
  @include mediaQuery(1500px) {
    width: 100%;
    padding: 0 30px;
  }
  @include mediaQuery($Largemobile) {
    margin: -69px auto 0;
    padding: 0 25px;
  }
  @include mediaQuery($mobile) {
    padding: 0 20px;
  }
  @include mediaQuery($mobileSmall) {
    padding: 0 10px;
  }
  div {
    max-height: 433px;
    // min-height: 100px;
    @include mediaQuery($Largemobile) {
      height: 100%;
      max-height: 223px;
    }
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      max-height: 433px;
      @include mediaQuery($Largemobile) {
        height: 100%;
        max-height: 223px;
      }
    }
  }
}

.sd_Panel_Speakers_title {
  h2 {
    @include fontStyle(500, 22, 60, $black, -0.528px);
    padding-left: 9px;
    @include mediaQuery($extraLarge) {
      padding-left: 0px;
    }
  }
}
.sd_PanelView_Carousel_des {
  display: flex;
  align-items: center;
  @include mediaQuery($extraLarge) {
    flex-direction: column-reverse;
  }
  .sd_PanelView_Carousel_left {
    width: 50%;
    @include mediaQuery($extraLarge) {
      width: 100%;
    }
    .sd_PanelView_Carousel_inner {
    padding-right: 30px;
      @include mediaQuery($extraLarge) {
        width: 100%;
        padding-bottom: 20px;
      }
      @include mediaQuery($iPad) {
        padding-bottom: 20px;
      }
      h2 {
        @include fontStyle(400, 60, 60, $black, -1.44px);
        padding-bottom: 47px;
        @include mediaQuery($extraLarge) {
          font-size: 50px;
          padding-right: 10px;
          padding-bottom: 25px;
        }
        @include mediaQuery($iPad) {
          font-size: 40px;
          padding-bottom: 20px;
          padding-right: 0;
          line-height: 42px;
        }
        @include mediaQuery($Largemobile) {
          font-size: 30px;
          padding-bottom: 10px;
        }
        @include mediaQuery($mobile) {
          font-size: 25px;
          line-height: 33px;
        }
        @include mediaQuery($smallMobile) {
          font-size: 20px;
          line-height: 30px;
        }
      }
      a {
        @include fontStyle(500, 22, 80, $black, -0.528px);
        border: 2px solid;
        padding: 0 20px;
        min-width: 230px;
        text-align: center;
        display: inline-block;
        @include mediaQuery($extraLarge) {
          line-height: 70px;
        }
        @include mediaQuery($Largemobile) {
          font-size: 22px;
          line-height: 60px;
        }
        @include mediaQuery($mobile) {
          font-size: 18px;
          line-height: 60px;
        }
        @include mediaQuery($smallMobile) {
          line-height: 50px;
        }

        &:hover {
          border-color: $black;
          background-color: $black;
          color: $white;
        }
      }
    }
  }
  .sd_PanelView_Carousel_right {
    width: 50%;

    @include mediaQuery($extraLarge) {
      padding-left: 10px;
    }

    @include mediaQuery($extraLarge) {
      width: 100%;
      padding: 0;
    }
  }
}

.sd_event_des_Panel {
  display: flex;
  padding-bottom: 75px;
  @include mediaQuery($iPad) {
    flex-direction: column;
  }
  @include mediaQuery($Largemobile) {
    padding-bottom: 35px;
  }
  @include mediaQuery($mobile) {
    padding-bottom: 25px;
  }
  .sd_event_detail_left_Panel {
    width: 45%;
    padding-right: 40px;
    box-sizing: border-box;
    @include mediaQuery($extraLarge) {
      width: 40%;
      padding-right: 25px;
    }
    @include mediaQuery($iPad) {
      width: 100%;
      padding-right: 0;
    }
    h2 {
      @include fontStyle(500, 22, 60, $black, -0.528px);
    }
    p {
      @include fontStyle(300, 16, 23, $black, -0.384px);
      padding-bottom: 15px;
      @include mediaQuery($iPad) {
        font-size: 15px;
      }
    }
  }
  // sd_Panel_Speakers_right
  .sd_panel_right_speakers {
    width: 55%;
    @include mediaQuery($iPad) {
      width: 100%;
    }
    .sd_event_detail_right {
      // width: 50%;
      // padding-left: 40px;
      box-sizing: border-box;
      @include mediaQuery($extraLarge) {
        padding-left: 25px;
      }
      @include mediaQuery($iPad) {
        width: 100%;
        padding-left: 0;
      }
      h2 {
        @include fontStyle(500, 22, 60, $black, -0.528px);
      }
      .sd_main_time {
        padding-bottom: 30px;
        @include mediaQuery($iPad) {
          padding-bottom: 15px;
        }
        @include mediaQuery($mobile) {
          padding-bottom: 10px;
        }
        .sd_detail_time {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          @include mediaQuery($iPad) {
            flex-wrap: wrap;
          }
          span {
            min-width: 20%;
            @include fontStyle(500, 16, 23, $black, -0.384px);
            width: 26%;
            @include mediaQuery($extraLarge) {
              min-width: 27%;
            }
            @include mediaQuery($extraLarge) {
              min-width: 100%;
            }
          }
          h3 {
            @include fontStyle(300, 16, 23, $black, -0.384px);
            @include mediaQuery($smallMobile) {
              width: 100%;
            }
          }
        }
      }
      
      .sd_main_panel_cta_btn
      {
        @include sd_main_panel_cta_btn;
        @include mediaQuery($mobile){
          display: none;
        }
      }
    }
    .sd_Panel_Speakers_right {
      display: flex;
      width: 875px;
      @include mediaQuery(1600px) {
        width: 100%;
      }
      @include mediaQuery($iPad) {
        flex-wrap: wrap;
      }
      .sd_panel_data {
        width: 200px;
        margin: 0 9px;
        @include mediaQuery($iPad) {
          // display: contents;
          width: 50%;
          margin: 0;
          padding: 0 10px;
        }
        @include mediaQuery($Largemobile) {
          width: 100%;
          padding: 20px 0 0;
        }
        .sd_inner_panel
        {
          @include mediaQuery($Largemobile) {
            display: flex;
            margin-bottom: 25px;
          }
        }
        .sd_panel_data_img {
          height: 165px;
          @include mediaQuery($iPad) {
            // display: contents;
            height: 230px;
          }
          @include mediaQuery($Largemobile) {
            height: 115px;
            width: 115px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .sd_panel_des {
          padding-top: 12px;
          h3 {
            @include fontStyle(500, 16, 23, $black, -0.384px);
            @include mediaQuery($Largemobile) {
              margin-left: 13px;
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}
.sd_main_panel_cta_btn_mobile
{
  @include sd_main_panel_cta_btn;
  display: none;
  margin: 47px auto;
  @include mediaQuery($mobile) {
    display: block;
  }
}
.sd_panel_view_bottom {
  padding: 52px 0 52px;
  h1 {
    width: 739px;
    @include fontStyle(400, 60, 60, $black, -1.44px);
    padding-bottom: 10px;
    @include mediaQuery($extraLarge) {
      font-size: 50px;
      line-height: 4rem;
    }
    @include mediaQuery($iPad) {
      font-size: 45px;
      padding-bottom: 10px;
    }
    @include mediaQuery($Largemobile) {
      font-size: 40px;
      padding-bottom: 10px;
    }
    @include mediaQuery($mobile) {
      font-size: 33px;
    }
    @include mediaQuery($mobileSmall) {
      font-size: 30px;
    }
    @include mediaQuery($smallMobile) {
      line-height: 30px;
    }
  }

  .sd_PanelView_inner {
    display: flex;
    height: 81px;
    align-items: center;
    margin-bottom: 40px;
    @include mediaQuery($iPad) {
      margin-bottom: 20px;
    }
    @include mediaQuery($Largemobile) {
      margin-bottom: 5px;
    }
    .sd_PanelView_img {
      width: 103px;
      height: 81px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      padding-right: 10px;
      padding-bottom: 0 !important;
      @include fontStyle(400, 10, 23, $black, -0.24px);
    }
  }
  .sd_Schedule {
    display: flex;
    padding-bottom: 15px;
    @include mediaQuery($iPad) {
      padding: 0;
    }
    @include mediaQuery($mobileSmall) {
      flex-wrap: wrap;
    }
    
    h3 {
      @include fontStyle(500, 22, 23, $black, -0.528px);
      span {
        padding-left: 15px;
        @include mediaQuery($mobile) {
          padding-left: 10px;
        }
        @include mediaQuery($mobileSmall) {
          padding-left: 5px;
        }
      }
      @include mediaQuery($Largemobile) {
        font-size: 20px;
      }
      @include mediaQuery($mobile) {
        font-size: 16px;
      }
      @include mediaQuery($smallMobile) {
        font-size: 15px;
      }
    }
    p {
      display: flex;
      align-items: center;
      @include mediaQuery($mobileSmall) {
        flex-wrap: wrap;
      }
      img {
        padding: 0 6px 0 20px;
        @include mediaQuery($mobile) {
          padding: 0 6px 0 10px;
          @include mediaQuery($mobileSmall) {
            padding: 5px 5px 0 0;
          }
        }
      }
      @include fontStyle(500, 10, 10, $white, -0.24px);
      @include mediaQuery($mobile) {
        font-size: 10px;
        padding-bottom: 10px;
      }
      @include mediaQuery($smallMobile) {
        font-size: 9px;
      }
    }
  }
}

.sd_PanelView_section {
  padding-bottom: 45px;
}

// carousel
.sd_PanelView_Carousel_right {
  .Carousel_panel_view {
    .slider-wrapper {
      max-height: 585px !important;
      @include mediaQuery(1500px) {
        max-height: 100%;
        height: auto !important;
      }
    }
    div {
      .carousel .slide img {
        max-height: 585px;
        width: 100%;
        object-fit: cover;
        @include mediaQuery($iPad) {
          max-height: 100%;
          height: auto;
        }
      }
    }
  }
}
