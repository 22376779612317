// Hero Header
.sd_hero_header {

  .sd_hero_header_inner {
    padding-top: 0;
    padding-left: 60px;
    max-width: 1510px;
    margin: 0 auto;
  }
  .sd_hero_header_text {
    max-width: 625px;
    width: 100%;
    padding: 100px 0 12px;
    @include mediaQuery($iPad) {
      padding: 50px 0 10px;
    }
    h1 {
      padding-top: 76px;
      margin-bottom: 10px;
      
    }
  }
}

.sponsors .sd_hero_header .sd_rtf_content {
  font-size: 16px !important;
  color: #000 !important;
}
