.sd_Cinema_Network_section
{
  .sd_header
  {
    background-color: $lygreen !important;
  }
}

.sd_CinemaBlock_section {
  padding-top: 23px;
  .sd_CinemaBlock_container {
    max-width: 1510px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    @include mediaQuery(1500px) {
      width: 100%;
      padding: 0 20px;
    }
    @include mediaQuery($desktop) {
      width: 100%;
      padding: 0 20px;
    }
    @include mediaQuery($Largemobile) {
      padding: 0 15px;
    }
    @include mediaQuery($smallMobile) {
      padding: 0 10px;
    }
    .sd_inner_CinemaBlock {
      display: flex;
      justify-content: flex-end;
      a {
        display: flex;
        align-items: center;
        @include fontStyle(500, 22, 26, $black, 0);
        .arrow_image {
          width: 30px;
          padding-top: 5px;
          padding-left: 10px;
          img {
            width: 23px;
          }
        }
      }
    }
    .sd_block_sec {
      padding-top: 35px;
      .sd_inner_block {
        display: flex;
        flex-wrap: wrap;
        .sd_block {
          // display: flex;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 25%;
          box-sizing: border-box;
          padding: 0 15px 50px;
          @include mediaQuery($desktop) {
            flex-basis: 33%;
            padding: 0 15px 40px;
          }
          @include mediaQuery($extraLarge) {
            flex-basis: 50%;
            padding: 0 15px 30px;
          }
          @include mediaQuery($iPad) {
            flex-basis: 100%;
            padding: 0 0px 35px;
            margin: 0;
          }
          @include mediaQuery($Largemobile) {
            padding: 0 0 25px;
          }
          .sd_block_images {
            overflow: hidden;
            // width: 100%;
            height: 199px;
            margin-bottom: 32px;
            @include mediaQuery($iPad) {
              height: auto;
            }
            img {
              width: 100%;
              height: 100%;
              // height: 199px;
              @include mediaQuery($iPad) {
                height: auto;
              }
            }
          }
          .sd_block_des {
            p {
              // text-transform: uppercase;
              @include fontStyle(400, 16, 23, $black, -0.384px);
              margin-top: 40px;
            }
            h3 {
              text-transform: capitalize;
              @include fontStyle(400, 40, 40, $black, -0.96px);
              @include mediaQuery($mobile) {
                font-size: 23px;
              }
              @include mediaQuery($mobileSmall) {
                font-size: 22px;
              }
            }
            .sd_learn_more
            {
              @include fontStyle(400, 22, 23, $black, -0.384px);
              margin-top: 24px;
              .sd_arrow
              {
                width: 6%;
                margin: 4px 0px 4px 15px;
              }
            }
          }
        }
      }
    }
  }
}

// setelite

.sd_header_hero_1,
.sd_header_hero_2 {
  margin: 0px auto 0 !important;
}

.sd_header_date_day {
  h4 {
    font-size: 18px;
    color: $white;
    font-weight: 200;
    font-family: "La Nord V2.1";
  }
}