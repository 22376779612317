.sd_slider {
  img {
    margin: auto;
  }
  .slick-arrow {
    z-index: 10;
    &:before,
    &:after {
      content: none;
    }
    &.slick-prev {
      @include mediaQuery($desktop) {
        left: 0;
      }
    }
    &.slick-next {
      transform: rotate(180deg);
      margin-top: -10px;
      @include mediaQuery($desktop) {
        right: 0;
      }
    }
    &.slick-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    margin: 40px 0;
  }
  .slick-dots li {
    height: 16px;
    width: 16px;
    margin: 0 5px;
  }
  .slick-dots li button {
    height: 16px;
    width: 16px;
    padding: 0;
  }
  .slick-dots li button:before {
    width: 16px;
    height: 16px;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid $black;
    border-radius: 50%;
    opacity: 1;
    color: transparent;
  }
  .slick-dots li.slick-active button:before {
    background: $black;
    opacity: 1;
  }
}

// carousel scss

// @media (min-width: 960px) {
//   .carousel .control-dots {
//     // bottom: 0;
//   }
// }
.carousel .control-dots {
  bottom: -45px !important;
}
.carousel.carousel-slider
{
  overflow: visible !important;
}
.carousel .control-dots {
  position: absolute !important;
  // bottom: -7px !important;
  // width: 110px !important;
  // margin: 35px auto !important;
  @include mediaQuery($extraLarge) {
    margin: 20px auto !important;
  }
  @include mediaQuery($mobile) {
    margin: 15px auto !important;
  }
}
.carousel .control-dots .dot {
  opacity: 1 !important;
  border: 1px solid black !important;
  box-shadow: none !important;
  width: 16px !important;
  height: 16px !important;
  cursor: pointer !important;
  display: inline-block;
  margin: 0 8px;
  background: none !important;
  @include mediaQuery($Largemobile) {
    width: 13px !important;
    height: 13px !important;
  }
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  background-color: black !important;
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
}
.carousel:hover .slide .legend {
  opacity: 1;
}

.thumbs-wrapper {
  display: none;
}
.carousel-status {
  display: none;
}

.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  //padding-bottom: 40px;
}
.control-next,
.control-prev {
  display: none;
}

// Slick slider arrows
.custom-slick-arrow {
  .slick-arrow {
    width: 32px;
    height: 26px;
    z-index: 5;
    @include mediaQuery($desktop) {
      width: 26px;
      height: 22px;
    }
    &:before {
      content: none;
    }
    &:after {
      @include content;
      background: url("/village/images/slider_arrow_left.svg");
      background-size: cover;
      width: 32px;
      height: 26px;
      top: 0;
      left: 0;
      @include mediaQuery($desktop) {
        width: 26px;
        height: 22px;
      }
    }
    &.slick-prev {
      left: 14px;
      @include mediaQuery($desktop) {
        left: 0px;
      }
    }

    &.slick-next {
      right: 14px;
      @include mediaQuery($desktop) {
        right: 0;
      }
      &:after {
        background: url("/village/images/slider_arrow_right.svg");
        background-size: cover;
      }
    }
    &.slick-disabled {
      opacity: 0.3;
      cursor: auto;
    }
  }
}

// Slick slider dots
.slick-slider {
  .slick-dots {
    bottom: 21px;
    @include mediaQuery($iPad) {
      bottom: 47px;
    }
    li {
      border: 1px solid $black;
      border-radius: 50%;
      @include transitionStyle(0.1, linear);
      @include square(10.4px);
      @include mediaQuery($iPad) {
        @include square(12px);
      }

      &.slick-active {
        background: $black;
      }
    }
    button {
      font-size: 0;
      line-height: 0;
      display: block;
      padding: 5px;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      /* margin: 12px; */
      cursor: pointer;
      @include square(10.4px);
      color: transparent;
      &:before {
        content: none;
      }
    }
  }
}

.sd_sponsore {
  padding: 10px 20px;
}

// Video Player
.controls_custom {
  height: 38px !important;
}
.sd_video_wpr {
  overflow: hidden;

  &:hover {
    .video-react-control-bar {
      transform: translateY(0) !important;
      &.video-react-control-out {
        transform: translateY(100%) !important;
      }
    }
  }
  .video-react-user-inactive .video-react-control-bar {
    transform: translateY(100%) !important;
  }

  .video-react-big-play-button {
    border: none !important;
    background: none !important;
    height: 90px !important;
    margin: 0 !important;
    transform: translate(-50%, -50%);
    z-index: 5;
    @include mediaQuery($mobile) {
      height: 50px !important;
      width: 50px !important;
    }
    &::before {
      background: url("../images/video_play_icon.svg") no-repeat;
      content: "" !important;
      @include mediaQuery($mobile) {
        background-size: 50px 50px;
      }
    }
  }

  .video-react {
    .video-react-control-bar {
      padding: 0 16px 0 7px;
      transition: 0.3s linear;
      transform: translateY(100%);
      @include mediaQuery($iPad) {
        padding: 0 10px 0 0;
      }
    }
    .video-react-play-control.video-react-playing {
      &:before {
        background: url("https://d2br0tbrfcvkl6.cloudfront.net/img/pause_btn.svg")
          no-repeat;
        content: "" !important;
        background-size: 24px;
        background-position: center center;
        @include mediaQuery($large) {
          background-size: 22px;
        }
      }
    }
    .video-react-play-control.video-react-paused {
      &:before {
        background: url("https://d2br0tbrfcvkl6.cloudfront.net/img/play_btn.svg")
          no-repeat;
        content: "" !important;
        background-size: 24px;
        background-position: center center;
        @include mediaQuery($large) {
          background-size: 22px;
        }
      }
    }
    .video-react-volume-menu-button {
      &:before {
        background: url("../images/volume_btn.svg") no-repeat;
        content: "" !important;
        background-size: 18px;
        background-position: 3px 10px;
        @include mediaQuery($large) {
          background-size: 16px;
          background-position: 3px 12px;
        }
      }
    }
    .video-react-slider {
      &:focus {
        box-shadow: none !important;
      }
    }

    .video-react-vol-muted {
      &:before {
        // background: url("https://d2br0tbrfcvkl6.cloudfront.net/img/mute_btn.svg") no-repeat;
        background: url("../images/mute_btn.svg") no-repeat;
        content: "" !important;
        background-size: 20px;
        background-position: 3px 10px;
        @include mediaQuery($large) {
          background-size: 18px;
          background-position: 3px 12px;
        }
      }
    }

    .video-react-volume-level,
    .video-react-play-progress {
      background-color: $activeBg !important;
    }
    .video-react-time-control {
      font-size: 11px !important;
      font-family: "La Nord V2.1";
      @include flex_align_center;
    }

    .video-react-menu-button-inline:hover .video-react-menu,
    .video-react-menu-button-inline:focus .video-react-menu,
    .video-react-menu-button-inline .video-react-menu-content {
      display: flex !important;
      @include align_center;
    }

    .video-react-fullscreen-control {
      width: 150px !important;
      @include mediaQuery($large) {
        width: 140px !important;
      }
      @include mediaQuery(576px) {
        width: 30px !important;
      }
    }
    .video-react-icon-fullscreen-exit {
      width: 120px !important;
      @include mediaQuery($large) {
        width: 100px !important;
      }
      @include mediaQuery(576px) {
        width: 30px !important;
      }
    }
    .video-react-icon-fullscreen:before,
    .video-react-icon-fullscreen-exit:before {
      background: url("https://d2br0tbrfcvkl6.cloudfront.net/img/zoom_btn.svg")
        no-repeat;
      content: "Full Screen" !important;
      background-size: 19px;
      background-position: right;
      font-family: "La Nord V2.1";
      color: $activeBg;
      font-size: 14px !important;
      line-height: 40px !important;

      @include mediaQuery($large) {
        background-size: 15px;
        font-size: 12px !important;
        line-height: 40px !important;
      }
      @include mediaQuery(576px) {
        content: "" !important;
        background-position: center;
      }
    }
    .video-react-icon-fullscreen-exit:before {
      content: "Exit" !important;
      @include mediaQuery(576px) {
        content: "" !important;
        background-position: center;
      }
    }
  }
}

.react-datepicker-popper{
  &[data-placement^="top"]{
      .react-datepicker__triangle{
          margin-bottom: 0 !important; 
      }
  }

  .react-datepicker{
      font-family: $font_family_normal;
      width: 100%;
      border: 0;
      box-shadow: 0 0 2px rgba($black, 0.3);
      border-radius: 3px;
  }

  .react-datepicker__triangle{
      // left: 50% !important;
      &::after{

        border-bottom-color: $lygreen !important;
      }
      &::before{
          top: 0 !important;
          border-bottom-color: $lygreen !important;
          border-top-color: $lygreen !important;
      }
  }
 
}

.sd_filter_by_day{

  .react-datepicker__header {
      background: $lygreen;
      .react-datepicker__current-month{
          @include defaultFontStyleFestival(400, 14, 23, $black);
          padding-bottom: 10px;
          @include mediaQuery($mobile){
              font-size: 12px;
          }
      }
      .react-datepicker__day-names{
          @include defaultFontStyleFestival(400, 12, 23, $black);
          @include mediaQuery($mobile){
              font-size: 10px;
          }
          .react-datepicker__day-name{
              width: 25px;
          }
      }
  }
  .react-datepicker__navigation{
      border-width: 5px;
      &--previous{
          border-right-color: $black;
      }
      &--next{
          border-left-color: $black;
      }
  }
  .react-datepicker__day{
      @include defaultFontStyleFestival(400, 12, 23, $black);
      width: 25px;
      @include mediaQuery($mobile){
          font-size: 10px;
      }
      &--today, &--keyboard-selected{
          background: $pink;
      }
      &.react-datepicker__day--selected{
          background: $lygreen;
      }
  }
}
.sd_filters_day_wpr{
  .react-datepicker-popper{
      z-index: 6;
  }
}
.sd_filter_by_time{
  .react-datepicker-popper{
      width: 100px;
      z-index: 5;
  }
  .react-datepicker__header{
      padding: 10px;
      border-radius: 3px 3px 0 0;
      background: $lygreen;
      border: 0;
      @include mediaQuery($mobile){
          padding: 5px;
      }
      > div {
          @include defaultFontStyleFestival(400, 14, 23, $black);
          @include mediaQuery($mobile){
              font-size: 12px;
          }
      }
  }
  
  .react-datepicker{
      .react-datepicker__time-container{
          width: 100%;
          .react-datepicker__time{
              border: none
          }
          .react-datepicker__time-box{
              width: 100% !important;
          }
  
          .react-datepicker__time-list-item {
              @include defaultFontStyleFestival(400, 14, 23, $black);
              @include mediaQuery($mobile){
                  font-size: 10px;
              }
              &.react-datepicker__time-list-item--selected{
                  background: $lygreen !important;
              }
          }
      }
  }
}
