// Popup
.sd_popup_wpr {
  position: absolute;
  top: 50%;
  margin: 0 auto;
  width: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 100;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba($white, 0.72);
  }
  .sd_popup_wpr_inner {
    background: $yellow;
    border: 2px solid $black;
    padding: 24px 48px 62px;
    max-width: 600px;
    overflow: auto;
    position: relative;
    z-index: 101;
    @include mediaQuery($iPad) {
      padding: 20px 20px 30px;
    }
  }
  .sd_popup_wpr_header {
    a {
      @include FontStyle(400, 10, 12, $white);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      max-width: 110px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      @include mediaQuery($iPad) {
        max-width: 100%;
      }
      img {
        margin-right: 10px;
        margin-left: -18px;
        @include mediaQuery($iPad) {
          margin-left: 0;
        }
      }
    }
  }
  .sd_popup_wpr_content {
    padding-top: 57px;
    @include mediaQuery($iPad) {
      padding-top: 30px;
    }
    p {
      @include FontStyle(400, 22, 27, $black);
      max-width: 500px;
      margin-top: 10px;
      @include mediaQuery($iPad) {
        font-size: 18px;
      }
      @include mediaQuery($mobile) {
        font-size: 16px;
      }
    }
  }

  .donate-btn {
    margin-top: 52px;
    text-align: center;
    @include mediaQuery($iPad) {
      margin-top: 30px;
    }
    button {
      max-width: 183px;
      width: 100%;
      @include mediaQuery($iPad) {
        font-size: 18px;
      }
      @include mediaQuery($mobile) {
        font-size: 16px;
        padding: 10px 20px;
      }
    }
  }
}


// cart popup

.sd_popup_light{
  position: fixed;
  top: 0;
  left: 0;
  @include square(100%);
  background: rgba($black, 0.1);
  z-index: 999;
  overflow: hidden;
  
  .sd_popup_light_inner{        
      max-width: 537px;
      margin: 0 auto; 
      width: 100%;
      max-height: 100%;
      background: $white;
      box-shadow: 0 0 15px 5px rgba($black, 0.15);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      overflow: auto;
      padding: 10px 40px 55px;
  }
  
  // Close Popup
  .sd_close_popup{
      position: absolute;
      top: 20px;
      right: 20px;
      @include square(20px);
      @include buttonReset;
      @include flex_justify_center;
      
      span{
          display: block;
          width: 2px;
          background: $black;
          height: 25px;
          &:first-child{
              transform: rotate(-45deg) translate(4px, -3px);
          }
          &:last-child{
              transform: rotate(45deg) translate(-4px, -3px);
          }
      }
  }
}
// Popup Header
.sd_popup_light_header{
  text-align: center;
  padding: 25px 0 0;
  h3{
      @include FontStyle(400, 26, 26, $black, -0.62px);  
  }
}

// Popup Content
.sd_popup_light_content{
  padding-top: 40px;
  p, pre{
      text-align: center;
      @include FontStyle(200, 16, 20, $black, -0.38px);  
      max-width: 405px;
      margin: 0 auto 15px;
      white-space: pre-wrap;

      @include mediaQuery($mobile){
          font-size: 14px;                 
      }     
  }

  h5{
      text-align: center;
      @include FontStyle(500, 18, 18, $black);  

  }

  .sd_input_error_msg{
      text-align: center;
      @include FontStyle(500, 12.5, 24, $pink, -0.3px); 
  }
}

// Popup Button
.sd_popup_light_buttons{
  @include flex_center_center;
  padding-top: 13px;
  button{
      @include FontStyle(500, 16.5, 16, $black, -0.4px); 
      max-width: 113px;
      width: 100%;
      padding: 17px 10px;  
      border: none;      
      cursor: pointer;
      @include transitionStyle(0.2, linear);
  }
  .sd_grey_btn{
      background: $lynxWhite2;
      margin-right: 18px;
      &:hover{
          background: $black;
          color: $white;
      }
  }
  .sd_yellow_fill_btn{
      background: $lightYellow;
      &:hover{
          background: $pink;
      }
  }
  .sd_pink_fill_btn{
      background: $pink;
      &:hover{
          background: $lightYellow;
      }
  }
}

// Remove Item
.sd_remove_item{
  h5{
      padding: 15px 0 20px;
  }
}

.sd_schedule_redeem {
  padding: 0 0 0 50px;

  p {
      font-style: italic;
  }
}

// Conditions Popup
.sd_popup_agree_cart{
  .sd_popup_light_inner{
      max-width: 1020px;
      @include mediaQuery($iPad){         
          padding: 10px 20px 55px;
      }     
      ul{
          margin-bottom: 10px;
      }
      li{
          margin-bottom: 5px;

          @include mediaQuery($mobile){
              font-size: 14px;                 
          }     
      }
  }   
}


// Popup
.sd_custom_popup {
  
  .sd_popup_inner {
      max-width: 537px;
      box-shadow: 0 0 15px 5px rgba($black, 0.15);
      padding: 10px 40px 55px;
      border: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include mediaQuery($iPad) {
          width: 95%;
      }
  }


  // Popup Content
  .sd_popup_content {
      padding: 50px 0 0;

      p,
      pre {
          text-align: center;
          margin: 0 auto 15px;

          @include mediaQuery($mobile) {
              font-size: 14px;
          }
      }

      a {
          text-transform: capitalize;
          color: $pink;
          &:hover{
              text-decoration: underline;
          }
      }

  }

}

.sd_free_item_popup{
  .sd_popup_light_inner{
      max-width: 688px;
      @include mediaQuery($iPad) {             
          width: 95%;
      }
      @include mediaQuery($mobile) { 
          padding: 10px 20px 55px;
      }
  }
  .sd_popup_light_content {
      padding-top: 20px;
  }  
  .sd_user_form{
      max-width: 100%;
  }
  .sd_free_item_zip{
      max-width: 510px;
      margin: 0 auto;
      .form_group{ 
          @include flex_center_center;
          @include flex_dir_row;
      }
      label {
          @include flex_shrink;
          padding-right: 24px;
          cursor: pointer;
          &:after, &::before{
              @include content;
              content:  '';
              left: 0;
          }
          &::after{  
              transform: rotate(-45deg);
              width: 6px;
              height: 3px;
              border: 1px solid $black; 
              border-top: transparent;
              border-right: transparent;
              opacity: 0;
              top: 8px !important;
          }  
      }
      input{
          max-width: 312px;
      }
  }
  .sd_free_item_button{
      text-align: center;
      padding-top: 20px;
      button{
          width: 100%;
          max-width: 198px;
          @include mediaQuery($mobile) {
              max-width: 150px;
              font-size: 16px;
              padding: 10px;
          }
      }
  }
}