// colors
$black: #000;
$white: #fff;
$purple: #7758B3;
$magenta: #146E96;
$lightGreen: #64FADC;
$yellow: #FFB30F;
$lightGrey: #C2C2C2;
$lynxWhite: #F7F7F7;
$switchBG: #F1F1F1;
$red: #e74c3c;
$whisper: #ececec;
$lightRed: #FE766F;
$tomato: #ff624b;
$pink: #FF625A;
$lighterGrey: #C9C9C9;
$gainsboro: #E1E0E0;
$nobel: #A2A2A2;
$yellowish: #FED105;
$activeBg:#FFCC09;
$lynxWhite2:#f7f7f7;
$lightYellow: #FFCC09;
$lygreen:#04AC80;
$drakblue:#00B2C0;
$darkpink:#C01A7D;
$lightGrey: #C2C2C2;
$lighterGrey: #C9C9C9;
$darkGrey: #9A9A9A;
$whiteSmoke: #F6F5F5;
$resetcolor:#FF4841;
// breakpoints
$smallMobile: 325px;
$mobileSmall: 375px;
$mobile: 479px;
$belowiPad: 767px;
$iPad: 768px;
$ipad: 768px;
$large: 991px;
$extraLarge: 1024px;
$desktop: 1366px;
$Largemobile:540px;
$mobileLarge: 576px;


$fontsmlight : "LaNordV21-SemiLight";
$fontlight : "LaNordV21-Light";
$fontreg : "LaNordV21-Regular";
$fontMed : "LaNordV21-Medium";
$fontbold : "LaNordV21-Bold";

// $font_family_normal:'Pangea';
// $font_family_bold:'Pangea_bold';
// $font_family_medium:'Pangea_medium';
// $font_family_light:'Pangea_light';

$font_family_normal:'Trade Gothic Next LT Pro';
$font_family_bold:'Trade Gothic Next LT Pro bold';
$font_family_medium:'Trade Gothic Next LT Pro';
$font_family_light:'Trade Gothic Next LT Pro';