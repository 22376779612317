// Newsletter
.sd_btn_black_button:hover {
  background: #000000;
  color: #fffffe !important;
}
.sd_btn_black_button {
  border: 2px solid #000;
  color: #000;
}
.sd_newsletter_sec {
  background: $lighterGrey;
  padding: 94px 0 122px;
  border-top: 2px solid $black;
  @include mediaQuery($extraLarge) {
    padding-left: 20px;
  }
  @include mediaQuery($iPad) {
    padding: 127px 9px 115px;
  }
  @include mediaQuery($mobile) {
    background: $gainsboro;
  }
  @include mediaQuery($smallMobile) {
    padding: 50px 0;
  }
  .sd_container {
    width: 1680px;
    margin: 0 auto;
    @include mediaQuery(1700px) {
      width: 100%;
      padding: 0 20px;
    }
    @include mediaQuery($iPad) {
      padding: 0;
    }
  }

  h2 {
    line-height: 45px;
    max-width: 472px;
    width: 100%;
    margin-bottom: 21px;
    @include fontStyle(400, 40, 40, $black, -0.96px);
    @include mediaQuery($large) {
      @include mediaFontStyle(36, 45);
      letter-spacing: -0.86px;
    }
    @include mediaQuery($mobile) {
      margin-bottom: 10px;
    }
    @include mediaQuery($smallMobile) {
      @include mediaFontStyle(26, 40);
    }
  }

  .sd_newsletter_form_inner {
    @include flex_dir_column;
  }

  .sd_form_group {
    max-width: 642px;
    width: 100%;

    input {
      background: $white;
      border: none;
      outline: none;
      @include fontStyle(200, 22, 40, $nobel);
      padding: 13px 33px 12px;
      width: 100%;
      margin-right: 20px;
      @include mediaQuery($iPad) {
        @include mediaFontStyle(18, 30);
      }
      @include mediaQuery($mobile) {
        @include mediaFontStyle(16, 26);
        padding: 8px 20px 7px;
        letter-spacing: -0.38px;
        margin-right: 7.78px;
      }
    }
  }

  .sd_submit_group {
    max-width: 150px;
    width: 100%;
    @include mediaQuery($mobile) {
      max-width: 77px;
    }
    button {
      width: 100%;
      min-width: 150px;
      text-transform: uppercase;
      @include mediaQuery($iPad) {
        @include fontStyle(500, 18, 30, $black);
        padding: 10px 30px;
      }
      @include mediaQuery($mobile) {
        min-width: 77px;
        @include mediaFontStyle(14.33, 26);
        padding: 5px 5px 6px;
      }
    }
  }

  // Error Message
  .sd_input_error_msg {
    @include mediaFontStyle(16, 26);
  }
}

.sd_input_success_msg {
  @include mediaFontStyle(16, 26);
}