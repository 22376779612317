article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary { display: block; }
// html, body { height: 100%; overflow: auto; }
html, body { height: 100%; display: block; }
body, ol, ul, li, div, p, span, form, h1, h2, h3, h4, h5, h6, table, thead, th, tbody, tr, td, img, input, textarea, dd, dt, dl { word-wrap: break-word; outline: none; padding: 0; margin: 0; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
textarea { overflow: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
h1, h2, h3, h4, h5, h6, p, ul, ol, dd, dt, dl { -webkit-margin-before: 0px; -webkit-margin-after: 0px; -webkit-margin-start: 0px; -webkit-margin-end: 0px; }
a img, img { border: none; outline: none; display: block; width: auto; max-width: 100%; }
a { cursor: pointer; text-decoration: none; outline: 0; }
input { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
html { font-size: 62.5%; }
body { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -ms-font-smoothing: antialiased; background-color: #fff; font-size: 62.5%; }
ul, menu, dir { margin: 0px; padding: 0px; display: block; list-style-type: disc; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-margin-start: 0; -webkit-margin-end: 0; -webkit-padding-start: 0; }
* {
  box-sizing: border-box;
  &:after, &:before {
    box-sizing: border-box;
  }
}
.clear { clear: both; height: 0px; overflow: hidden; width: auto; display: block; float: none !important; }
.no-script-msg { font: 12px Arial, verdana, Helvetica, sans-serif; background: #ffffcc; width: auto; padding: 10px 10px 10px 25px; margin: 8px; border: 1px solid #cd3d18; font-weight: bold; height: auto; font-size: 11px; color: #000000; line-height: 150%; clear: both; }
li:last-child, p:last-child { margin-bottom: 0 !important; }
img { object-fit: cover !important;}
// .sd_container{
//   max-width: 1710px;
//   width: 100%;
//   margin: 0 auto;
//   padding: 0 15px;
//   &.sd_inner_container{
//     max-width: 1510px;
//     padding: 0 15px;
//   }
// }
.sd_flex_1_auto {flex: 1 1 auto;}
.sd_inner_container{
    max-width: 1510px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
/* loader */
.sd_loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: rgba(0, 0, 0, 0.15);
  z-index: 100;
  svg{
    margin: auto;
    position: relative;
    z-index: 101;
  }
}

.logo_Day {
  position: absolute;
  width: 82px;
  height: 82px;
  background-color: #C01A7D;
  padding: 16px 0 11px;
  text-align: center;
  box-sizing: border-box;
  h3{
    font-size: 20px;
    font-weight: 200;
    color: white;
    font-family: $font_family_normal;
  }
  h4 {
    font-size: 14px;
    font-weight: 200;
    color: white;
    font-family: $font_family_normal;
  }
  span {
    font-size: 30px;
    color: white;
    font-family: $font_family_normal;
    font-weight: 500;
  }
}

.sd_user_wpr .sd_user_profile_cart .sd_label.sd_label_yellow {
  background: #fed105 !important;
}

.ml-2{
  margin-left: 5px;
}
.font-weight-700{
  font-weight: 700;
}


.sd_cat_label{
  span{
    background: #f7f7f7 !important;
    text-transform: uppercase;
    padding: 5px;
    font-family: 'Pangea' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    color: #000000;
    margin-right: 13px;
    margin-bottom: 5px;
    position: relative;
    z-index: 3;
      img{
          cursor: pointer;
          margin-left: 10px;
      }
  }
}

// .sd_presention_left_inner.sd_presention {
//   flex-basis: 25% !important;
// }

// .custom-pagination ul li.active a, .custom-pagination ul li:hover a {
//   background: #00B2C0;
//   border-radius: 50%;
// }
.custom-pagination ul li.active a{
  font-weight: 700;
  background: #00B2C0;
  border-radius: 50%;
  color: white;
}
.custom-pagination {
  margin-bottom: 54px !important;
  border-top: 2px solid;
  padding-top: 31px;
  margin-top: 60px;
}

.pagination li {
  list-style: none;
  margin-right: 10px;
}
