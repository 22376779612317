.sd_simplelp_bg {
  background: url("/village/images/red-blue-bg.jpg") no-repeat center center;
  background-size: cover;
  &.sd_simplelp_bottom {
    padding-bottom: 100px;
    @include mediaQuery($belowiPad) {
      padding-bottom: 50px;
    }
  }
}
.sd_simplelp_section {
  .sd_simplelp_left {
    h1 {
      @include defaultFontStyle(normal, 60, 60, $black);
      letter-spacing: -1.44px;
      @include mediaQuery($extraLarge) {
        font-size: 4rem;
        line-height: 4rem;
      }
    }
    .sd_sponsoredby {
      margin: 10px 0;
    }
    .sd_datetime {
      @include defaultFontStyle(500, 22, 22, $black);
      letter-spacing: -0.53px;
      margin-top: 70px;
    }
    h3 {
      @include defaultFontStyle(normal, 22, 27, $black);
      margin-top: 20px;
      letter-spacing: -0.53px;
    }
  }
  .sd_simplelp_right {
    max-width: calc(100% - 625px);
    width: 100%;
    padding-left: 30px;
    @include mediaQuery($extraLarge) {
      padding-left: 0px;
      max-width: 100%;
    }
    .sd_right_inner {
      max-width: 770px;
      width: 100%;
      margin-left: auto;
      @include mediaQuery($extraLarge) {
        max-width: 100%;
      }
      img {
        width: 100%;
      }
      .sd_time_location {
        li {
          list-style: none;
          margin-bottom: 25px;
          span {
            @include defaultFontStyle(500, 16, 24, $black);
            letter-spacing: -0.38px;
            min-width: 140px;
            text-transform: lowercase;
          }
          p {
            @include defaultFontStyle(300, 16, 24, $black);
            letter-spacing: -0.38px;
          }
        }
      }
    }
  }
  .sd_btn {
    max-width: 230px;
    width: 100%;
    margin-top: 75px;
  }
}

// events & timing section
.sd_event_details {
  margin: 70px 0;
  h3.sd_heading {
    margin-top: 0;
    letter-spacing: -0.53px;
    @include defaultFontStyle(500, 22, 60, $black);
  }
  .sd_simplelp_left {
    p {
      margin-bottom: 25px;
    }
  }
}

.sd_full_image {
  margin-bottom: 85px;
  @include mediaQuery($iPad) {
    margin-bottom: 55px;
  }
  .sd_fullimage_inner {
    img {
      width: 100%;
    }
  }
}
