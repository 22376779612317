.sd_Artist_Lodge_section {
  .sd_medium_border {
    border: 0;
    margin: 0 !important;
  }
  .sd_artists_daily_data {
    background: url("/village/images/artist.png");
    background-repeat: no-repeat;
    padding-top: 129px;
    background-size: contain;
    @include mediaQuery($desktop) {

      padding-top: 122px;
    }
    @media only screen and (max-width: 1200px) and (min-width: 1060px)  {
      padding-top: 100px;
    }
    @include mediaQuery(1040px) {
      padding-top: 105px;
    }
    @include mediaQuery($extraLarge) {
      padding-top: 102px;
    }
    @include mediaQuery($large) {
      background: none;
      padding-top: 0;
    }
    .sd_artists_main {
      background-color: #ff625a;
      @include mediaQuery($desktop) {
        padding: 0 20px;
      }
      @include mediaQuery($Largemobile) {
        padding: 0 15px;
      }
      @include mediaQuery($smallMobile) {
        padding: 0 10px;
      }
    }
    .sd_artist_container {
      width: 1680px;
      margin: 0 auto;
      @include mediaQuery(1500px) {
        width: 100%;
      }
      @include mediaQuery($large) {
        padding-top: 40px;
      }
      @include mediaQuery($smallMobile) {
        padding-top: 20px;
      }
      .sd_artists_daily_data_header {
        padding-top: 20px;
        h2 {
          text-transform: uppercase;
          @include fontStyle(400, 60, 40, $black, -1.44px);
          @include mediaQuery($iPad) {
            font-size: 50px;
          }
          @include mediaQuery($Largemobile) {
            font-size: 40px;
          }
          @include mediaQuery($mobile) {
            font-size: 30px;
          }
          @include mediaQuery($smallMobile) {
            font-size: 25px;
          }
        }
        p {
          @include fontStyle(bold, 18, 80, $black, -0.432px);
          text-transform: uppercase;
          @include mediaQuery($Largemobile) {
            font-size: 16px;
            line-height: 30px;
          }
          @include mediaQuery($mobile) {
            font-size: 15px;
            line-height: 19px;
          }
          @include mediaQuery($smallMobile) {
            font-size: 13px;
            line-height: 15px;
          }
        }
      }
      .sd_artists_daily_block {
        padding-top: 35px;
        display: flex;
        flex-wrap: wrap;
        @include mediaQuery($desktop) {
          padding-top: 30px;
        }
        .sd_artists_daily_block_inner {
          display: flex;
          padding: 0 20px 45px;
          width: 50%;
          @include mediaQuery($desktop) {
            flex-direction: column;
            padding: 0 15px 45px;
          }
          @include mediaQuery($iPad) {
            width: 100%;
            padding: 0 0 45px;
          }
          @include mediaQuery($Largemobile) {
            padding: 0 0 15px;
          }

          .sd_artists_block_images {
            img {
              width: 269px;
              @include mediaQuery($desktop) {
                width: 100%;
                max-height: 360px;
              }
              @include mediaQuery($iPad) {
                width: 100%;
                max-height: 100%;
                height: auto;
              }
            }
          }
          .sd_artists_block_description {
            width: 100%;
            padding-left: 21px;
            @include mediaQuery($desktop) {
              width: 100%;
              padding: 20px 0 0;
            }
            @include mediaQuery($mobile) {
              padding: 15px 0 0;
            }
            h2 {
              text-transform: uppercase;
              padding-bottom: 10px;
              display: flex;
              align-items: center;

              @include fontStyle(400, 30, 32, $black, -0.72px);
              @include mediaQuery($Largemobile) {
                font-size: 25px;
                line-height: 25px;
                padding-bottom: 5px;
              }
              @include mediaQuery($Largemobile) {
                font-size: 22px;
              }
            }
            p {
              @include fontStyle(400, 16, 23, $black, -0.384px);
              @include mediaQuery($mobile) {
                font-size: 14px;
              }
            }

            a {
              display: flex;
              align-items: center;
              @include fontStyle(500, 22, 80, $black, -0.528px);
              @include mediaQuery($iPad) {
                line-height: 3rem;
              }
              .offer-title {
                padding-right: 10px;
              }
              .sd_arrow {
                width: 5%;
                @include mediaQuery($desktop) {
                  width: 4%;
                }
                @include mediaQuery($iPad) {
                  width: 4%;
                }
                @include mediaQuery($mobileSmall) {
                  width: 7%;
                }
                img {
                  width: 100%;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

.artist_data {
  p {
    color: #000 !important;
    span {
      font-size: 16px !important;
      background: transparent !important;
      line-height: 23px !important;
      font-family: $font_family_normal !important;
      font-weight: 400 !important;
      color: #000 !important;
      display: inline-block !important;
    }
    a {
      color: $purple !important;
      font-size: 16px !important;
      background: transparent !important;
      line-height: 23px !important;
      font-family: $font_family_normal !important;
      font-weight: 400 !important;
      color: $purple !important;
     min-width: unset !important;
      span {
        font-size: 16px !important;
        background: transparent !important;
        line-height: 23px !important;
        font-family: $font_family_normal !important;
        font-weight: 400 !important;
        color: $purple !important;
      }
    }
    ul {
      li{
        font-size: 16px !important;
          background: transparent !important;
          line-height: 23px !important;
          font-family: $font_family_normal !important;
          font-weight: 400 !important;
          color: #000 !important;
          display: inline-block !important;
        span {
          font-size: 16px !important;
          background: transparent !important;
          line-height: 23px !important;
          font-family: $font_family_normal !important;
          font-weight: 400 !important;
          color: #000 !important;
          display: inline-block !important;
        }
        a {
          color: $purple !important;
          font-size: 16px !important;
          background: transparent !important;
          line-height: 23px !important;
          font-family: $font_family_normal !important;
          font-weight: 400 !important;
          color: $purple !important;
          span {
            font-size: 16px !important;
            background: transparent !important;
            line-height: 23px !important;
            font-family: $font_family_normal !important;
            font-weight: 400 !important;
            color: $purple !important;
          }
        }
      }
    }
    ol {
      li{
        font-size: 16px !important;
          background: transparent !important;
          line-height: 23px !important;
          font-family: $font_family_normal !important;
          font-weight: 400 !important;
          color: #000 !important;
          display: inline-block !important;
        span {
          font-size: 16px !important;
          background: transparent !important;
          line-height: 23px !important;
          font-family: $font_family_normal !important;
          font-weight: 400 !important;
          color: #000 !important;
          display: inline-block !important;
        }
        a {
          color: $purple !important;
          font-size: 16px !important;
          background: transparent !important;
          line-height: 23px !important;
          font-family: $font_family_normal !important;
          font-weight: 400 !important;
          color: $purple !important;
          span {
            font-size: 16px !important;
            background: transparent !important;
            line-height: 23px !important;
            font-family: $font_family_normal !important;
            font-weight: 400 !important;
            color: $purple !important;
          }
        }
      }
    }
  }
}

.sd_artist_button {
  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
.slider-images{ width: 100%;}
.slider-images img{ width: 100%; display: block;}
.swiper-slide {
  font-size: 18px;
  background: #fff;
  width: 30% !important;
  /* Center slide text vertically */
  display: flex;
  @include mediaQuery($extraLarge) {
    width: 40% !important;
  }
  @include mediaQuery($iPad) {
    width: 70% !important;
    padding-right: 30px;
  }
  @include mediaQuery($Largemobile) {

    padding-right: 20px;
  }

}

.slider-artist {
  @include mediaQuery($desktop) {
    padding: 30px 0  30px 20px!important;
  }
  @include mediaQuery($Largemobile) {
    padding: 30px 0  30px 15px!important;
  }
  @include mediaQuery($mobile) {
    padding: 18px 0  18px 15px!important;
  }
  @include mediaQuery($mobileSmall) {
    padding: 18px 0  18px 10px!important;
  }
}