// Sponsor Section
.sd_sponsored_section {
  margin-top: -2px;
  border-top: 2px solid $black;
  position: relative;

  .sd_sponsored_inner {
    width: 1680px;
    margin: 0 auto;
    @include mediaQuery(1700px) {
     width: 100%;
  }
}
  .sd_sponsored_inner_flex {
    @include mediaQuery($iPad) {
      @include flex_dir_column;
    }
    @include mediaQuery($mobileLarge) {
            
      display: none;
   }
  }
  .sd_logo_sec_wpr{
    width: 100%;
    img{
        margin: 0 auto;
    }
}

  .sd_sponsored_title {
    transform: rotate(-90deg) translateY(60px);
    letter-spacing: -0.14px;
    text-align: center;
    @include defaultFontStyle(400, 22, 26, $black);
    text-transform: uppercase;
    width: 180px;
    display: inline-block;
    margin-left: -110px;
    flex-shrink: 0;
    @include mediaQuery(1630px) {
      margin-left: -120px;
    }
    @include mediaQuery($iPad) {
      transform: rotate(0deg);
      margin: 10px 0 25px 0;
      width: 100%;
      font-size: 18px;
    }
    @include mediaQuery($mobile) {
      font-size: 14.4px;
      @include mediaFontStyle(14.4, 16);
      letter-spacing: -0.09px;
      max-width: 160px;
    }
    @include mediaQuery($smallMobile) {
      margin-bottom: 50px;
    }
  }
  .sd_logo_section {
    width: calc(100% - 50px);
    padding: 70px 0;
    max-width: 1440px;
    @include mediaQuery($large) {
      padding: 50px 0;
    }
    @include mediaQuery($iPad) {
      width: 100%;
      padding: 20px 0 100px;
    }
  }
  // .sd_chat_with_us
  // {
  //   position:absolute;
  //   top:0;
  //   right:0;
  //   .sd_inner_chat
  //   {
  //     height:138px;
  //     width:138px;
  //     background:#FFC52B;
  //     border-radius: 50%;
  //     text-align:center;
  //     box-shadow: 1px 4px 5px 2px rgba(0, 0, 0, 0.0552885), 0px 1px 2px 1px rgba(0, 0, 0, 0.203535);
  //   }
  // }
  
}
.sd_view_all_sponsors {
  position: absolute;
  bottom: 0;
  right: 15px;
  @include mediaQuery($iPad) {
    left: 50%;
    transform: translateX(-50%);
  }
 
  a {
    @include defaultFontStyle(500, 14, 14, $black);
    letter-spacing: -0.34px;
    @include flex;
    align-items: baseline;
    justify-content: flex-end;
    padding-bottom: 17px;
    @include mediaQuery($iPad) {
      @include mediaFontStyle(11.2, 11.2);
      letter-spacing: -0.27px;
      @include justify_center;
      padding-bottom: 15px;
      align-items: flex-end;
    }
    
    img {
      margin-left: 12px;
      width: 18px;
    }
  }
}

.slick-slide {
  outline: none;
  width: 1404px;
}
.slick-slide {
  img {
    margin: 0 auto;
  }
}
.sd_hero1_slider_sec{
  display: none;
  position: relative;
  @include mediaQuery($mobileLarge) {
          
      display: block;
      margin: 20px 15px 30px;
   }
   @include mediaQuery(320px) {
          margin: 20px 0px 30px;
   }
   span{
      position: absolute;
      z-index: 2;
      top: 15px;
      &:nth-child(2){
          position: absolute;
          right: 0;
      }
  }
   .sd_Mobilelogo_sec_wpr{
     
      margin: 0 30px;
     
   }
   .sd_mobile{
       position: relative;
      
       p{
          text-align: center;
          text-transform: uppercase;
          width: 130px;
          margin: 0 auto;
       }
      }
      
       .sd_img{
          margin-top: 30px;
          .logo_img{
            width: 135px; 
              // max-height: 93px;
              // margin-bottom: 50px;
              margin:0 auto 50px;
              @include mediaQuery(365px) {
                  width: 130px;
              }
              img{
                  width: 100%;
                  object-fit: contain !important;
                  max-height: 100%;
              }
          }
         
       }
      //  margin: 20px 30px 30px;
      .sd_logo_sec_wpr{
          @include flex-wrap;
      }
   
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
.sd_sponsore_sec_d{
  display: flex; align-items: center;
  @include mediaQuery($iPad) {
  flex-wrap: wrap;
  justify-content:center;
  }
}

