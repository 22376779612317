//PartnerBlock blogs
.sd_Partner_section 
{
  .sd_header 
  {
    background-color: $darkpink;
  }
  .sd_simplelp_left
  {
    margin-right: 40px;
  }
}

.sd_PartnerBlock {
    border-bottom: 2px solid $black;
  }
  .sd_PartnerBlock_container {
    padding: 75px 0 26px;
    max-width:1680px;
    margin: 0 auto;
    @include mediaQuery(1680px) {
      width: 100%;
      padding: 55px 20px 26px;
    }
    @include mediaQuery($desktop) {
      padding: 40px 20px 26px;
    }
    @include mediaQuery($mobile) {
      padding: 40px 15px;
    }
    @include mediaQuery($smallMobile) {
      padding: 40px 10px;
    }
    .sd_PartnerBlock_inner {
      .sd_blogs_sec {
        div {
          position: relative;
        }
        .sd_PartnerBlock_tag {
          background-color: #00B2C0;
          height: 28px;
          padding: 4px 0;
          text-align: center;
          position: absolute;
          top: -14px;
          left: -14px;
          z-index: 1;
          @include mediaQuery($smallMobile) {
            left: -7px;
          }
          p {
            padding: 0 10px;
            @include fontStyle(bold, 10, 20, $black, -0.384px);
            text-transform: uppercase;
          }
        }
  
        .tag_high_text {
          p {
            letter-spacing: 1px !important;
            line-height: 20px !important;
            border: none !important;
          }
        }
        .sd_inner_blog {
          padding: 47px 30px 45px;
          border: 2px solid;
          margin-bottom: 50px;
          @include mediaQuery($extraLarge) {
            margin-bottom: 0px;
          }
          @include mediaQuery($iPad) {
            padding: 35px 15px 16px;
          }
          @include mediaQuery($smallMobile) {
            padding: 35px 25px 16px;
          }
          .sd_blog_img {
            img {
              width: 100%;
            }
            .sd_sec_img_high {
              padding-top: 10px;
            }
          }
          .sd_blog_description {
            .sd_blog_content {
              padding-top: 19px;
              h2 {
                @include fontStyle(400, 26, 28, $black, -0.624px);
                text-transform: uppercase;
              }
            }
            .sd_blog_event {
              padding-top: 14px;
              .sd_Partner_event {
                padding-bottom: 27px;
                &:last-child {
                  padding-bottom: 0;
                }
                a {
                  display: flex;
                  align-items: center;
                  padding-bottom: 8px;
                  @include fontStyle(400, 20, 23, $black, -0.48px);
                  .event-title {
                    width: 92%;
                    @include mediaQuery($smallMobile) {
                      width: 92%;      
                    }
                  }
                  .right_arrow {
                    width: 8%;      
                    @include mediaQuery($extraLarge) {
                      width: 5%;      
                    }
                    img {
                      width: 100%;
                    }
                  }
                }
                p {
                  @include fontStyle(bold, 16, 20, $black, -0.384px);
                  text-transform: uppercase;
                  span {
                    padding-left: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 28px;
  }
  
  .video-react .video-react-video {
    width: 100% !important;
  }
  // .sd_inner_container {
  //   padding: 0;
  // }
  
  // partner all event
  .sd_partner_event {
    padding: 29px 0;
    .sd_partnerevent_container {
      width: 1680px;
      margin: 0 auto;
      @include mediaQuery(1600px) {
        width: 100%;
      }
      @include mediaQuery($desktop) {
        padding: 0;
      }
      @include mediaQuery($Largemobile) {
        padding: 0 20px;
      }
      @include mediaQuery($mobile) {
        padding: 0 15px;
      }
      @include mediaQuery($smallMobile) {
        padding: 0 10px;
      }
      .sd_inner_partnerevent {
        h2 {
          @include fontStyle(400, 40, 40, $black, -0.96px);
          text-transform: uppercase;
          padding: 0 0 39px 19px;
          @include mediaQuery($Largemobile) {
            font-size: 35px;
            padding: 0 0 39px;
          }
          @include mediaQuery($mobile) {
            font-size: 30px;
          }
          @include mediaQuery($smallMobile) {
            font-size: 24px;
            line-height: 29px;
          }
        }
      }
      .sd_block_sec {
        .sd_inner_block {
          display: flex;
          flex-wrap: wrap;
          .sd_block {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 25%;
            box-sizing: border-box;
            padding: 0 19px 49px;
            @include mediaQuery(1440px) {
              flex-basis: 25%;
            }
            @include mediaQuery($desktop) {
              flex-basis: 33.2%;
            }
            @include mediaQuery($extraLarge) {
              flex-basis: 50%;
            }
  
            @include mediaQuery($Largemobile) {
              flex-basis: 100%;
              margin: 0;
              padding: 0 0 25px;
            }
            .sd_block_images {
              width: 100%;
              img {
                width: 100%;
                height: 250px;
                @include mediaQuery($desktop) {
                  height: 264px;
                }
                @include mediaQuery($Largemobile) {
                  height: auto;
                }
              }
            }
            .sd_block_des {
              padding-top: 10px;
              h3 {
                text-transform: capitalize;
                @include fontStyle(400, 26, 28, $black, -0.624px);
                padding-bottom: 9px;
                @include mediaQuery($Largemobile) {
                  font-size: 24px;
                }
              }
              a {
                display: flex;
                align-items: center;
                @include fontStyle(500, 22, 80, $black, -0.528px);
                @include mediaQuery($extraLarge) {
                  line-height: 60px;
                }
                @include mediaQuery($iPad) {
                  line-height: 50px;
                }
                @include mediaQuery($Largemobile) {
                  font-size: 20px;
                  line-height: 40px;
                }
                .right_arrow {
                  width: 30px;
                  margin-top: 5px;
                  padding-left: 10px;
                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  