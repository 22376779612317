.sd_btn {
  @include defaultFontStyle(500, 22, 22, $purple);
  letter-spacing: -0.53px;
  text-align: center;
  border: 2px solid $purple;
  background: transparent;
  padding: 20px 30px;
  cursor: pointer;
  outline: none;
  &_purple {
    border: 2px solid $purple;
    color: $purple;
  }
  &_magenta {
    border: 2px solid $magenta;
    color: $magenta;
  }
  &_black {
    border: 2px solid $black;
    color: $black;
  }
  &_pink {
    border: 2px solid $pink;
    color: $pink;
  }
  &_lightGreen {
    border: 2px solid $black;
    color: $black;
  }
}

// switch
.sd_switchbtn-toggle {
  margin: 0 16px;
  padding: 0;
  position: relative;
  border: none;
  height: 30px;
  width: 68px;
  border-radius: 1.5rem;
  background: $switchBG;
  cursor: pointer;
}
.sd_switchbtn-toggle:focus,
.sd_switchbtn-toggle.focus,
.sd_switchbtn-toggle:focus.active,
.sd_switchbtn-toggle.focus.active {
  outline: none;
}
.sd_switchbtn-toggle:before,
.sd_switchbtn-toggle:after {
  width: 80px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  bottom: -5px;
  transition: opacity 0.25s;
  letter-spacing: -0.43px;
  @include defaultFontStyle(500, 18, 22, $black);
}
.sd_switchbtn-toggle:before {
  content: attr(data-left);
  left: -96px;
}
.sd_switchbtn-toggle:after {
  content: attr(data-right);
  right: -96px;
  color: #e1e0e0;
}
.sd_switchbtn-toggle > .handle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  border-radius: 1.125rem;
  background: $black;
  transition: left 0.25s;
}
.sd_switchbtn-toggle.active {
  transition: background-color 0.25s;
}
.sd_switchbtn-toggle.active > .handle {
  left: 40px;
  transition: left 0.25s;
}
.sd_switchbtn-toggle.active:before {
  // opacity: 0.5;
  color: #e1e0e0;
}
.sd_switchbtn-toggle.active:after {
  // opacity: 1;
  color: $black;
}

// sponsored button
.sd_sponsored_btn {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
