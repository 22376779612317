.sd_GeneralView {
  background: url("/village/images/simple_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  // background-size: 100% 100%;
  @include mediaQuery($iPad) {
    background: none;
    background-color: #ff625a;
  }
}

.sd_inner_container {
  padding: 0 15px;
  .sd_GeneralView_header {
    padding-top: 83px;
    display: flex;
    @include mediaQuery($extraLarge) {
      padding-top: 58px;
    }
    @include mediaQuery($iPad) {
      flex-direction: column;
      padding-top: 45px;
    }
    .sd_GeneralView_left {
      width: 50%;
      padding-right: 38px;
      padding-bottom: 96px;
      @include mediaQuery($desktop) {
        padding-right: 25px;
      }
      @include mediaQuery($extraLarge) {
        padding-right: 10px;
      }
      @include mediaQuery($iPad) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 40px;
      }
      @include mediaQuery($Largemobile) {
        padding-bottom: 30px;
      }
      h1 {
        @include fontStyle(400, 60, 60, $black, -1.44px);
        padding-bottom: 5px;
        @include mediaQuery($extraLarge) {
          font-size: 50px;
          line-height: 4rem;
        }
        @include mediaQuery($iPad) {
          font-size: 45px;
          padding-bottom: 10px;
        }
        @include mediaQuery($Largemobile) {
          font-size: 30px;
          line-height: 32px;
          padding-bottom: 10px;
        }
        @include mediaQuery($mobile) {
          font-size: 25px;
        }
        @include mediaQuery($smallMobile) {
          font-size: 22px;
          line-height: 27px;
        }
      }
      .sd_GeneralView_inner {
        display: flex;
        align-items: center;
        padding-bottom: 33px;
        @include mediaQuery($extraLarge) {
          padding-bottom: 20px;
        }
        @include mediaQuery($iPad) {
          padding-bottom: 10px;
        }

        p {
          padding-bottom: 0;
          @include fontStyle(400, 10, 23, $black, -0.24px);
        }
        .sd_GeneralView_img {
          width: 103px;
          padding-left: 4px;
          img {
            width: 100%;
          }
        }
      }
      .sd_Schedule {
        display: flex;
        padding-bottom: 8px;
        @include mediaQuery($iPad) {
          padding: 5px;
        }
        @include mediaQuery($mobile) {
          flex-wrap: wrap;
        }
        h3 {
          @include fontStyle(500, 22, 23, $black, -0.528px);
          span {
            padding-left: 15px;
            @include mediaQuery($mobileSmall) {
              padding-left: 5px;
            }
          }
          @include mediaQuery($extraLarge) {
            font-size: 18px;
          }
          @include mediaQuery($Largemobile) {
            font-size: 17px;
          }
          @include mediaQuery($mobile) {
            font-size: 15px;
          }
        }
        p {
          display: flex;
          align-items: center;
          padding: 0;
          img {
            padding: 0 6px 0 20px;
            @include mediaQuery($mobile) {
              padding-left: 0;
              padding-top: 5px;
            }
          }
          @include fontStyle(500, 10, 10, $white, -0.24px);
          @include mediaQuery($mobile) {
            font-size: 9px;
            padding-bottom: 10px;
          }
        }
        button {
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 0;
          img {
            padding: 0 6px 0 20px;
            @include mediaQuery($mobile) {
              padding-left: 0;
              padding-top: 5px;
            }
          }
          @include fontStyle(500, 10, 10, $white, -0.24px);
          @include mediaQuery($mobile) {
            font-size: 9px;
            padding-bottom: 10px;
          }
        }
      }

      a {
        @include fontStyle(500, 22, 80, $black, -0.528px);
        border: 2px solid;
        text-align: center;
        display: inline-block;
        min-width: 230px;
        padding: 0 20px;
        &:hover {
          background: $black;
          color: $white;
          border-color: $black;
        }
        @include mediaQuery($extraLarge) {
          line-height: 70px;
        }
        @include mediaQuery($Largemobile) {
          font-size: 22px;
          line-height: 60px;
        }
        @include mediaQuery($mobile) {
          font-size: 18px;
          line-height: 60px;
        }
        @include mediaQuery($smallMobile) {
          line-height: 50px;
        }
      }
    }
    .sd_GeneralView_right {
      width: 50%;
      padding-left: 38px;
      margin-bottom: 50px;
      @include mediaQuery($desktop) {
        padding-left: 25px;
      }
      @include mediaQuery($extraLarge) {
        padding-right: 10px;
      }
      @include mediaQuery($iPad) {
        width: 100%;
        padding: 0 0 45px;
        margin-bottom: 0;
      }
      @include mediaQuery($smallMobile) {
        padding: 0 0 30px;
      }
      .offer-image {
        padding-bottom: 65px;
      }
      .general_images {
        max-height: 433px;
        img {
          max-height: 433px;
          width: 100%;
        }
      }
    }
  }
}

.sd_Event_Details {
  padding: 70px 0 0px;
  @include mediaQuery($iPad) {
    padding: 30px 0 0px;
  }
  @include mediaQuery($mobile) {
    padding: 20px 0 0px;
  }
  .sd_event_des {
    display: flex;
    padding-bottom: 55px;
    @include mediaQuery($desktop) {
      padding-bottom: 30px;
    }
    @include mediaQuery($iPad) {
      flex-direction: column;
    }
    @include mediaQuery($Largemobile) {
      padding-bottom: 35px;
    }
    .sd_event_detail_left {
      width: 50%;
      padding-right: 40px;
      box-sizing: border-box;
      @include mediaQuery($extraLarge) {
        padding-right: 25px;
      }
      @include mediaQuery($iPad) {
        width: 100%;
        padding-right: 0;
      }
      h2 {
        @include fontStyle(500, 22, 60, $black, -0.528px);
      }
      p {
        @include fontStyle(300, 16, 23, $black, -0.384px);
        padding-bottom: 15px;
        @include mediaQuery($iPad) {
          padding-bottom: 5px;
        }
      }
    }
    .sd_event_detail_right {
      width: 50%;
      padding-left: 40px;
      box-sizing: border-box;
      @include mediaQuery($extraLarge) {
        padding-left: 25px;
      }
      @include mediaQuery($iPad) {
        width: 100%;
        padding-left: 0;
      }
      h2 {
        @include fontStyle(500, 22, 60, $black, -0.528px);
      }
      .sd_main_time {
        padding-bottom: 30px;
        @include mediaQuery($iPad) {
          padding-bottom: 15px;
        }
        @include mediaQuery($mobile) {
          padding-bottom: 10px;
        }
        .sd_detail_time {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          @include mediaQuery($iPad) {
            flex-wrap: wrap;
          }
          span {
            min-width: 20%;
            @include fontStyle(500, 16, 23, $black, -0.384px);
            width: 26%;
            @include mediaQuery($extraLarge) {
              min-width: 27%;
            }
            @include mediaQuery($extraLarge) {
              min-width: 100%;
            }
          }
          h3 {
            @include fontStyle(300, 16, 23, $black, -0.384px);
            @include mediaQuery($smallMobile) {
              width: 100%;
            }
          }
        }
      }
      a {
        @include fontStyle(500, 22, 80, $black, -0.528px);
        border: 2px solid;
        display: inline-block;
        min-width: 230px;
        padding: 0 20px;
        text-align: center;
        @include mediaQuery($extraLarge) {
          line-height: 70px;
        }
        @include mediaQuery($Largemobile) {
          font-size: 22px;
          line-height: 60px;
        }
        @include mediaQuery($mobile) {
          font-size: 18px;
          line-height: 60px;
        }
        @include mediaQuery($smallMobile) {
          line-height: 50px;
        }
        &:hover {
          background-color: $black;
          color: $white;
        }
      }
    }
  }
}

.general_span {
  padding-bottom: 33px;
  @include mediaQuery($desktop) {
    padding-bottom: 25px;
  }
}
.iframeContainer {
  padding-top: 56.25%;
  position: relative;
}
.iframeContainer iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border: none;
  left: 0;
}

.sd_offer_logo {
  padding-bottom: 30px !important;
}
.hideImage {
  display: none;
}
.offer-img {
  margin-bottom: 50px;
  max-height: 517px;
  @include mediaQuery($extraLarge) {
    max-height: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  img {
    width: 100%;
    max-height: 517px;
    @include mediaQuery($extraLarge) {
      max-height: 100%;
      height: auto;
    }
  }
}
.sd_offer-view {
  @include mediaQuery($extraLarge) {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .sd_GeneralView_left {
    @include mediaQuery($extraLarge) {
      width: 100% !important;
      padding-right: 0px !important;
      padding-bottom: 50px !important;
    }
  }
  .sd_GeneralView_right {
    @include mediaQuery($extraLarge) {
      width: 100% !important;
      padding-left: 0 !important;
    }
  }
}

.detail_span {
  text-transform: uppercase;
  min-width: 20%;
  @include fontStyle(500, 16, 23, $black, -0.384px);
  width: 127px;
  @include mediaQuery($extraLarge) {
    min-width: 10%;
  }
}

.right-icon {
  width: 41px;
  height: 14px;
}
.isimage {
  margin-bottom: 30px;
}
