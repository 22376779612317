// Sponsors page
.sponsors{
    .sd_header_section{
        // background: url("/images/header_bg.png"), $pink;
        // @include border_bottom_black;
        background-size: 100% 100%;
        background-position: 0 -2px;
        background-repeat: no-repeat;
        // padding-bottom: 80px;
        @include mediaQuery($large){
            padding-bottom: 79px;
        }
        @include mediaQuery($ipad){
            background-size: cover;
        }
        @include mediaQuery(700px){
            padding-bottom: 109px;
        }
    }
    .sd_hero1_slider_sec
    {
        @include mediaQuery($mobileLarge) {
            display: block;
            margin: 0px 15px 30px;
        }
        .sd_sponsored_mobile
        {
            padding: 40px 0 70px 0;
            @include border_bottom_black;
            margin: 0 -15px;
            &:last-child{
                border: none;
            }  
            .sd_sponsors_main
            {
                .sd_sponsored_inner_flex
                {
                    display: block;
                }
            } 
        }
    }
    .sd_hero_header{
        .sd_inner_container{
            // padding-left: 60px;
            @include mediaQuery($ipad){
                padding-left: 15px;
            }
        }
        .sd_rtf_content{
            @include rtf_content;
             
            *{
                font-size: 22px !important;
                line-height: 32px;
              @include mediaQuery($mobileLarge){
                font-size: 18px !important;
                line-height: 28px;
            }
            }
        }
      
    }
}
.sd_sponsors_header
{
    background: url("/village/images/sponser11.png");
    background-size: cover;
    // background-position: -2px 0;
    background-repeat: no-repeat;
    @include mediaQuery($ipad){
        // background: url("/village/images/mobile_header_bg.png");
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
    }
    @include mediaQuery($large) {
        background-position: -185px;
      }
    @include mediaQuery($mobile) {
        background-image: url("/village/images/sponser22.png");
    //   background-size: cover;
      background-position: bottom
      }
    @include border_bottom_black;
    // padding-bottom: 80px;
    .sd_hero_header_inner
    {
        padding: 102px 15px 50px;
        @include mediaQuery($extraLarge){
            padding: 85px 15px 15px;
        }
        @include mediaQuery($mobile){
             padding: 162px 15px 30px;
        }
        @include mediaQuery(400px){
            padding: 170px 15px 44px;
       }
        .sd_hero_header_title{
            // margin: 0 auto;
            max-width: 980px;
            // text-align: center;
            padding-right: 0;
            @include mediaQuery($desktop){
                flex-shrink: 1;
            } 
            @include mediaQuery($extraLarge){
                padding-bottom: 30px;
                max-width: 100%;
            }
            @include mediaQuery($mobile){
                padding-bottom: 15px;
                max-width: 100%;
            }
    @include mediaQuery($mobileLarge)
            {
                h1
                {
                    @include mediaFontStyle(33, 40); 
                    letter-spacing: -0.26px !important;
                    padding-bottom: 12px;
                }
            }
           h1{
             letter-spacing: normal;
            }
        }
    }
}
.sd_sponsors_page{
    .sd_view_all_sponsors{
        display: none;
    }
    .sd_sponsored_section{
        // border-top: 0;
        padding: 70px 0;
        border-bottom: 2px solid $black;
        border-top: none;
        &:last-child{
            border: none;
        }
        @include mediaQuery($ipad){
            padding: 40px 0;
                
        }
        @include mediaQuery($mobileLarge)
        {
            display: none; 
        }
    }
}
.sd_sponsore {
    padding: 10px 20px;
}
