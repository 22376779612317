// Donor
.sd_donor_page {
  header {
    z-index: 5;
  }
  .sd_header_text_sec {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(60%, $magenta),
      color-stop(40%, $white)
    );
    background: -o-linear-gradient(top, $magenta 60%, $white 40%);
    background: linear-gradient(to bottom, $magenta 60%, $white 40%);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      background: url(/images/donor-bg-1.png) no-repeat;
      top: 0;
      right: 0;
      background-size: auto;
      width: 100%;
      height: 100%;
      background-position: top right;
      z-index: 2;
    }
    &:before {
      content: "";
      position: absolute;
      background: url(/images/donor-bg-2.png) no-repeat;
      top: 20px;
      left: 0;
      background-size: cover;
      width: 100%;
      height: 100%;
      background-position: top left;
      z-index: 1;
    }
  }
  .sd_header_section {
    padding-bottom: 60px;
  }
  .sd_header_text_left {
    position: relative;
    z-index: 4;
  }
}
.sd_small_card_donor {
  .sd_seemore_inner {
    span {
      color: $magenta;
    }
  }
  .sd_smallcard_inner {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @include mediaQuery($desktop) {
      margin: 0 !important;
    }
  }
  .sd_seemore {
    width: 140px;
    &.sd_seemore_donor {
      span {
        color: $magenta;
      }
      &:hover {
        svg path {
          fill: $magenta;
        }
        svg polygon {
          fill: $white;
        }
      }
    }
  }
}

.sd_mediumcard_inner {
  .sd_mediumcard_view.sd_mediumcard_view_donor {
    @include mediaQuery($mobile) {
      margin-bottom: 0;
    }
  }
}
