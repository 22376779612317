/* display property */
.sd_block{ display: block; }
.sd_inlineblock { display: inline-block; }
.sd_hidden{ display: none; }
.sd_events_none{ pointer-events: none; }

/* flexbox */
.sd_flex { display: flex; }
.sd_flexwrap{ flex-wrap: wrap; }
.sd_aligncenter{ align-items: center; }
.sd_justbetween{ justify-content: space-between; }
.sd_justcenter{ justify-content: center; }

/* align */
.sd_aligntop {vertical-align: top;}
.sd_alignmiddle {vertical-align: middle;}
.sd_alignbottom {vertical-align: bottom;}

/* positions */
.sd_relative{ position: relative; }
.sd_absolute{ position: absolute; }
.sd_top0 {top: 0;}
.sd_right0 {right: 0;}
.sd_bottom0 {bottom: 0;}
.sd_left0	{left: 0;}
.sd_z0 {z-index: 0;}
.sd_z1 {z-index: 1;}
.sd_z5 {z-index: 5;}


/* texts */
.sd_textcenter { text-align: center; }
.sd_textleft { text-align: left; }
.sd_textright { text-align: right; }
.sd_textuppercase { text-transform: uppercase;}
.sd_textlowercase { text-transform: lowercase;}
.sd_textcapitalize { text-transform: capitalize;}

/* object fit */
.sd_objectcontain	{-o-object-fit: contain;object-fit: contain;}
.sd_objectcover {-o-object-fit: cover;object-fit: cover;}

