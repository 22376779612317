.sd_branded_lp {
  background: $lightGreen;
  .sd_branded_header {
    margin-bottom: 55px;
    padding-top: 45px;
    .sp_branded_heading {
      line-height: 3rem;
      display: inline-block;
      // @include mediaQuery($mobile) {
      //   line-height: 4rem;
      // }
      sup {
        position: relative;
        top: -20px;
        right: -10px;
        @include mediaQuery($mobile) {
          top: -10px;
        }
        svg {
          cursor: pointer;
          z-index: 5;
          position: relative;
        }
        &:hover {
          svg path {
            fill: #fff;
          }
          .partner_info_box {
            display: block;
          }
        }
        .partner_info_box {
          display: none;
          position: absolute;
          width: 158px;
          left: 27px;
          bottom: 31px;
          background: #fff;
          box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.3);
          padding: 16px 12px 19px 11px;
          &:before {
            content: "";
            position: absolute;
            width: 13px;
            height: 53px;
            background: url("/village/images/partner_info_shape.svg");
            left: -13px;
            bottom: -16px;
            z-index: 0;
          }
          p {
            @include defaultFontStyle(400, 12, 16, $black);
            text-transform: initial;
          }
        }
      }
    }
    .sp_sapphire_logo {
      margin: 20px 0 30px 0;
      display: block;
    }
    .sd_header_inner {
      max-width: 710px;
    }
  }
  .sd_card_content {
    .sd_time {
      color: $black;
    }
  }
}
