// Filters and Categories
// .sd_filter_category_section{
//     overflow: hidden;
// }
.sd_filter_category_wpr{
    @include flex_center_between;
    position: relative;
    @include mediaQuery($large) {
        @include flex_dir_column;
        align-items: flex-start;
    }
    @include mediaQuery($ipad) {
        @include flex_dir_row;
        align-items: flex-end;
        padding: 0 2px 0 3px;
    }
    @include mediaQuery($smallMobile) {
        @include flex_dir_column;
    }
    
    
    button{
        @include buttonReset;
        @include defaultFontStyle(500, 14, 32, $black);
        letter-spacing: -0.53px;
        text-transform: uppercase;
        @include mediaQuery($extraLarge) {
            @include mediaFontStyle(18, 30);
        }
        @include mediaQuery($mobile) {
            @include mediaFontStyle(14.4, 30);
            letter-spacing: -0.35px;
            // @include defaultFontStyle(600, 14, 32, $black);
        }
    }
    .sd_filter_category_left{
        @include flex_align_center;
        @include mediaQuery($large) {
            padding-bottom: 30px;
        }
        @include mediaQuery($ipad) {
            @include f_wrap;
            padding-bottom: 0;
            width: 100%;
        }
    }
}

// Switch
.sd_category_btn{
    @include flex_align_center;
    margin-right: 62px;
    @include mediaQuery($extraLarge) {
        margin-right: 40px;
    }
    @include mediaQuery($ipad) {
        order: 1;
        width: 100%;
        margin-bottom: 26px;
    }
    span{
        @include defaultFontStyle(500, 22, 32, $gainsboro);
        letter-spacing: -0.53px;
        text-transform: uppercase;
        @include transitionStyle(0.2, linear);
        cursor: pointer;
        @include mediaQuery($extraLarge) {
            @include mediaFontStyle(18, 30);
        }
        @include mediaQuery($mobile) {
            @include mediaFontStyle(16, 30);
            letter-spacing: -0.38px;
        }
        @include mediaQuery($smallMobile) {
            @include mediaFontStyle(14, 22);
        }
        &.active{
            color: $black;
        }
    }
    button{
        position: relative;
        margin: 0 16px;
        height: 30px;
        width: 68px;
        border-radius: 50px;
        background: $switchBG;
        cursor: pointer;
        @include transitionStyle(0.2, linear);
        @include mediaQuery($ipad) {
            height: 24px;
            width: 55px;
            margin: 0 10px;
        }
        &:before{
            @include content;
            top: 3px;
            left: 3px;
            @include square(24px);
            border-radius: 50%;
            background: $black;
            @include transitionStyle(0.2, linear);
            @include mediaQuery($ipad) {
                top: 2.4px;
                @include square(19.2px);
            }
        }
        
        &.sd_category_switched{
            &:before{
                transform: translateX(38px);
                @include mediaQuery($ipad) {
                    transform: translateX(30px);                    
                }
            }

        }
    }
}

// Search 
.sd_filter_search_wpr{
    display: none;
    @include mediaQuery($ipad) {
        // display: block;
        // margin-top: 15px;
        // position: static;
        // margin-left: auto;
    }
    position: absolute;
    right: 0;
    top: 0;
    .sd_filter_search{      
        &.active{
            input{
                right: 310px;
                top: -10px;
                z-index: 55;
                display: none;
            }
            button{
                z-index: 56;
                position: relative;
            }
        }
    }
    input{
        position: absolute;
        transform: translateX(100%);
        @include defaultFontStyle(500, 17, 22, $darkGrey);
        letter-spacing: 0.96px;
        border: none;
        background: $whiteSmoke;
        width: 320px;
        padding: 15px 50px 13px 15px;
        text-transform: uppercase;
        letter-spacing: -0.41px;
        @include transitionStyle(0.2, linear);
        @include mediaQuery($smallMobile) {
            font-size: 15px;
            padding: 10px 50px 10px 10px;
        }
        &::placeholder{
            color: $darkGrey;
        }
    }
    img{
        width: 29px;
        @include mediaQuery($smallMobile) {
            width: 20px;
        }
    }
}
// Schedule
.sd_schedule_btn{
    @include mediaQuery($ipad) {
        order: 4;
        width: 200px;
    }
    @include mediaQuery($mobile) {
        width: auto;
    }

}
// Sort By - Select
.sd_sort_by{
    @include flex_align_center;
    label{
        @include defaultFontStyleFestival($font_family_bold, 18, 18, $black);
        text-transform: uppercase;
        display: block;
        margin-right: 8px;
    }
    .sd_form_group_select{
        select {
            @include defaultFontStyleFestival($font_family_medium, 18, 18, $black);
            @include buttonReset;
            appearance: none;
            background: url('/village/images/sort_arrow_down.svg') no-repeat;
            background-position: center right;
            padding: 0 36px 3px 21px;
            border-bottom: 1px solid $black;
        }
    }

    &.sd_sort_mobile{
        display: none;
        @include mediaQuery($ipad) {
            display: block;
        }
        @include mediaQuery($mobile) {
            margin-top: -22px;
        }
        @include mediaQuery($smallMobile) {
            margin-top: 15px;
        }
        select{
            border: none;
            text-transform: uppercase;
            padding: 0;
            @include defaultFontStyle(700, 18, 30, $black);
            background-size: 9px 5px;
            padding-right: 19px;
            direction: rtl;
            @include mediaQuery($mobile) {
                @include mediaFontStyle(10.37, 10);
                direction: rtl;
                padding-right: 20px;        
            }
        }
    }
    &.sd_sort_desktop{
        @include mediaQuery($ipad) {
            display: none;
        }
    }
}
